export default {
  services_number_title: "Número de servicios para elegir",
  additional_information_after_booking_title:
    "Información adicional en el correo electrónico después de la reserva",
  additional_information_after_booking_subtitle:
    "Información adicional que se enviará en los correos electrónicos relacionados con las reservas de servicios (creación, modificación y recordatorios sobre la reserva). Si has editado las notificaciones, asegúrate de que la variable correspondiente esté configurada en su contenido: [ADDITIONAL_INFORMATION_AFTER_BOOKING].",
  additional_information_after_booking_dummy_text:
    "Este es un texto de ejemplo que será reemplazado por información adicional después de la reserva, si dicha información ha sido proporcionada en la configuración del servicio.",
  plugins_storage_100gb_cd_base_pro_name: "Almacenamiento 100 GB",
  plugins_storage_500gb_cd_base_pro_name: "Almacenamiento 500 GB",
  plugins_storage_100gb_cd_base_pro_description:
    "Más espacio para archivos. Obtén 100 GB adicionales para compartir archivos con clientes y recibir adjuntos a través de formularios de reserva. Una solución ideal para optimizar la comunicación y mejorar la atención al cliente.",
  plugins_storage_500gb_cd_base_pro_description:
    "Espacio máximo para tus archivos. Usa 500 GB de almacenamiento para intercambiar archivos con clientes y recibir adjuntos a través de formularios de reserva sin limitaciones. Esencial para empresas en rápido crecimiento que valoran el profesionalismo y la comodidad.",
  bookings_this_month: "Reservas este mes",
  bookings_all_processed: "Todas las reservas",
  simple_store_product_transactions_this_month: "Productos vendidos este mes",
  files_storage_usage: "Almacenamiento usado",
  files_storage_usage_from_available_prefix: "de los disponibles",
  not_available_in_standard_plan: "Función no disponible en el plan Standard",
};
