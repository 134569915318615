import { mapGetters } from 'vuex'
import { TenantPlanSlug } from '@/calendesk/models/BuilderTypes'
import Vue from 'vue'

export default Vue.extend({
  computed: {
    ...mapGetters({
      currentTenantPlanSlug: 'setup/getCurrentTenantPlanSlug'
    }),
    openGraphAvailable (): boolean {
      if (this.currentTenantPlanSlug) {
        return this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_STANDARD || this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_PRO
      }

      return false
    },
    modifyUrlAvailable (): boolean {
      if (this.currentTenantPlanSlug) {
        return this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_STANDARD || this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_PRO
      }

      return false
    },
    googleTagAvailable (): boolean {
      if (this.currentTenantPlanSlug) {
        return this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_STANDARD || this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_PRO
      }

      return false
    },
    filesStorageAvailable (): boolean {
      if (this.currentTenantPlanSlug) {
        return this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_PRO
      }

      return false
    },
    widgetsAvailable (): boolean {
      if (this.currentTenantPlanSlug) {
        return this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_STANDARD || this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_PRO
      }

      return false
    },
    hideSectionsAvailable (): boolean {
      if (this.currentTenantPlanSlug) {
        return this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_STANDARD || this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_PRO
      }

      return false
    },
    customDomainAvailable (): boolean {
      if (this.currentTenantPlanSlug) {
        return this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_STANDARD || this.currentTenantPlanSlug === TenantPlanSlug.CD_BASE_PRO
      }

      return false
    }
  },
  methods: {
    openFeatureNotAvailableDialog () {
      this.$root.$emit('openFeatureNotAvailableDialog')
    }
  }
})
