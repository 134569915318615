export default {
  services_number_title: "Anzahl der wählbaren Dienstleistungen",
  additional_information_after_booking_title:
    "Zusätzliche Informationen in der E-Mail nach der Buchung",
  additional_information_after_booking_subtitle:
    "Zusätzliche Informationen, die in E-Mails im Zusammenhang mit Servicebuchungen gesendet werden (Erstellung, Änderung und Erinnerungen an die Buchung). Wenn Sie die Benachrichtigungen bearbeitet haben, stellen Sie sicher, dass die entsprechende Variable in deren Inhalt festgelegt ist: [ADDITIONAL_INFORMATION_AFTER_BOOKING].",
  additional_information_after_booking_dummy_text:
    "Dies ist ein Beispieltext, der durch zusätzliche Informationen nach der Buchung ersetzt wird, sofern diese in den Serviceeinstellungen angegeben wurden.",
  plugins_storage_100gb_cd_base_pro_name: "Dateispeicher 100 GB",
  plugins_storage_500gb_cd_base_pro_name: "Dateispeicher 500 GB",
  plugins_storage_100gb_cd_base_pro_description:
    "Mehr Speicherplatz für Dateien. Gewinnen Sie 100 GB zusätzlichen Platz, um Dateien mit Kunden auszutauschen und Anhänge über Buchungsformulare zu empfangen. Eine ideale Lösung, um die Kommunikation zu verbessern und den Kundenservice zu optimieren.",
  plugins_storage_500gb_cd_base_pro_description:
    "Maximaler Speicherplatz für Ihre Dateien. Nutzen Sie 500 GB Speicherplatz, um Dateien mit Kunden auszutauschen und Anhänge über Buchungsformulare ohne Einschränkungen zu empfangen. Unverzichtbar für schnell wachsende Unternehmen, die Professionalität und Komfort schätzen.",
  bookings_this_month: "Buchungen diesen Monat",
  bookings_all_processed: "Alle Buchungen",
  simple_store_product_transactions_this_month:
    "Verkaufte Produkte diesen Monat",
  files_storage_usage: "Genutzter Speicherplatz",
  files_storage_usage_from_available_prefix: "von verfügbaren",
  not_available_in_standard_plan: "Funktion nicht im Standardplan verfügbar",
};
