export default {
  transaction_id: "Transaction ID",
  service_date: "Date of service",
  change_plan_button_title: "Change your plan",
  trial_period: "Trial period",
  minimum_value_for_payment_error: "The minimum value is 2.",
  choose_tenant_dialog_title: "Please select the company you want to log in to",
  select: "Select",
  choose_tenant_dialog_forgotten_password_title:
    "Select the company for which you want to change the password",
  user_uses_email_already:
    "The selected email address is used by a customer or another employee.",
  fields_required: "* – required fields",
  add_resources_with_plus:
    'No resources. Add resources by clicking "+" on the right.',
  add_categories_with_plus:
    'No categories. Add a category by clicking "+" on the right.',
  email_sent: "The email has been sent",
  sms_sent: "The text message has been sent",
  payment_method_missing:
    'You don\'t have a connected payment method. Go to the "Billing" tab and add a payment method.\t',
  invoice_data_missing:
    'You don\'t have completed VAT invoice data. Go to the "Billing" tab and fill in the data.',
  my_web_template: "My website",
  no_websites_info:
    "You haven't added any websites yet, click below to add the first one.",
  no_website_info_button: "Add a website",
  no_mobile_apps_info:
    "You don't have a mobile app project yet, click below to add the first one.",
  no_mobile_apps_info_button: "Create a mobile app",
  my_mobile_app_template: "My mobile app",
  company_name_2: "Company name or first and last name",
  go_back: "Back",
  new_feature: "A new feature in Calendesk",
  notifications_banner_text:
    "Personalize your email, SMS, and push notifications in a few simple steps.",
  edit_now: "Edit now",
  notification_templates_list: "Templates",
  notification_templates_list_header: "Panel / Notifications",
  booking_prepayment: "Booking confirmation - payment required",
  booking_prepayment_info:
    'This notification is sent if the booked service requires payment or if the created booking has the status of "Prepayment required." The notification is not sent if the "Booking confirmation" setting is turned off or if the option "Don\'t send a notification after creating a visit" was selected when creating the booking.',
  booking_created: "Booking confirmation",
  booking_created_info:
    'This notification is sent after creating a booking with the status "Approved" and after payment is made. A notification is not sent if the "Booking confirmation" setting is turned off or if the "Don\'t send a notification after creating a visit" option was selected when creating the booking.',
  booking_updated: "Booking change",
  booking_updated_info:
    'This notification is sent after changing a booking (i.e., a change in status, date or time of the meeting, employee, customer, location, or service has been made). A notification is not sent if the "Booking change" notification type is turned off or if the "Don\'t send a notification after changing/deleting the visit" option was selected when changing the booking.',
  booking_deleted: "Booking cancellation",
  booking_deleted_info:
    'This notification is sent after deleting or canceling a booking. A notification is not sent if the "Booking cancellation" setting type is turned off or if the "Don\'t send a notification after deleting the event" option was selected when deleting the booking.',
  booking_reminder: "Booking reminder",
  booking_reminder_info:
    'The notification is sent to bookings with the "Approved" status according to the set time before the reservation is made. The notification is not sent if the "Booking reminder" notification type is turned off.',
  account_activate: "Account activation - welcome email",
  account_activate_info:
    "This notification is sent after a customer has created their own account on the Calendesk-generated page.",
  "account_password-reset": "Reset password",
  "account_password-reset_info":
    "This notification is sent after using the password reminder option on the Calendesk-generated page.",
  newsletter_subscribe: "Newsletter subscription",
  newsletter_subscribe_info:
    "This notification is sent after using the newsletter sign-up option on the Calendesk-generated page.",
  "simple-store-product-transaction_paid":
    "Store - product purchase confirmation",
  edited: "Edited",
  "simple-store-product-transaction_paid_info":
    "The notification is sent to the main email address of the account after purchasing products on the page generated in Calendesk. The notification is not sent when purchasing products during the booking of the visit (notifications regarding bookings already contain this information).",
  notification_info:
    "Editing notifications will help you adjust the content of notifications to your requirements. You can use the advanced editing of notifications, using the so-called variables, or leave the default settings. Default notifications contain all the necessary information your clients and employees should receive.",
  notification_templates_list_for_client: "Notifications to your customer",
  notification_templates_list_for_tenant: "Notifications to your employee",
  notification_templates_list_for_tenant_info:
    "Notifications to employees are sent to the employees' e-mail addresses or, if they are missing, to the account's primary address (Settings / Your company tab).",
  notification_templates_list_information:
    "In the menu on the left, you can customize your notifications. You have the option to use variables in the message content. Variables are in the format “[NAME]” and will be automatically replaced with appropriate values, such as the service name or the customer’s name. These values are retrieved from service settings and user accounts. To select a variable, click the blue button symbolizing “+” (plus) next to the message title, or the blue “Click here” button located under the field where you set the notification, and choose the variable you want to include in the content.",
  ready: "Ready",
  notification_templates_list_preview_title: "Preview of sample messages",
  notification_template_editor_sidebar_email_title: "Email",
  notification_template_editor_sidebar_sms_title: "SMS",
  notification_template_editor_sidebar_push_title: "Mobile push",
  notification_template_editor_sidebar_text: "Text",
  notification_template_editor_publish_title:
    "Changing the content of notifications",
  notification_template_editor_publish_cancel_title: "Delete changes",
  notification_template_editor_publish_text:
    "The change will take effect immediately.",
  notification_template_editor_publish_cancel_text:
    "The changes made will be deleted.",
  notification_template_editor_publish_warning: "This action cannot be undone!",
  notification_template_editor_publish_confirmation_button:
    "Change notifications",
  notification_template_editor_publish_cancel_confirmation_button:
    "Delete changes",
  notification_template_editor_sidebar_restore_all: "Restore default content",
  notification_template_editor_sidebar_restore_all_information:
    "The default notifications from this group will be restored.",
  notification_template_editor_sidebar_restore_all_button: "Restore",
  booking_prepayment_email_subject_client:
    "Payment required: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_prepayment_email_text_client:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p>Your booking for <b>[SERVICE_NAME]</b> requires payment.</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Amount:</b><br>[BOOKING_PRICE]</p>[BOOKING_CUSTOM_FIELDS]<p><b>Your booking will be cancelled if no payment is made within [BOOKING_AWAITING_PAYMENT_FULL_TIME]. Click below to proceed to your booking and make the payment.</b></p><p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON]',
  booking_created_email_subject_client:
    "Confirmed: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_created_email_text_client:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p>Your booking for <b>[SERVICE_NAME]</b> has been confirmed.</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Location:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON][ADD_TO_CALENDAR_BUTTONS]',
  booking_created_email_subject_tenant:
    "New booking: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_created_email_text_tenant:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p><b>A new booking has been made for the service:</b><br> [SERVICE_NAME]</p><p><b>Client:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Employee:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Location:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_updated_email_subject_client:
    "Updated: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_updated_email_text_client:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p>Your booking for <b>[SERVICE_NAME]</b> has been updated.</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Location:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON][ADD_TO_CALENDAR_BUTTONS]',
  booking_updated_email_subject_tenant:
    "Updated: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_updated_email_text_tenant:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p><b>The booking has been changed:</b><br> [SERVICE_NAME]</p><p><b>Client:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Employee:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Location:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_deleted_email_subject_client:
    "Canceled: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_deleted_email_text_client:
    "<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p>We would like to inform you that your booking for <b>[SERVICE_NAME]</b> has been cancelled.</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p>",
  booking_deleted_email_subject_tenant:
    "Canceled: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_deleted_email_text_tenant:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p>We would like to inform you that the booking for <b>[SERVICE_NAME]</b> has been cancelled.</p><p><b>Client:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Employee:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_reminder_email_subject_client:
    "Reminder: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_reminder_email_text_client:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p>This is a reminder for your booking for <b>[SERVICE_NAME]</b></p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Location:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON]',
  booking_reminder_email_subject_tenant:
    "Reminder: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_reminder_email_text_tenant:
    '<h1>Booking number: [BOOKING_ID]</h1><hr/><p>Hi,</p><p>This is a reminder for the booking for the service:</p><p> [SERVICE_NAME]</p><p><b>Client:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Employee:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Date and time:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Location:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_created_push_subject_client: "Thank you for booking the appointment",
  booking_created_push_text_client:
    "[SERVICE_NAME] with [COMPANY_NAME] on [BOOKING_START_DATE] at [BOOKING_START_TIME].",
  booking_created_push_subject_tenant: "[COMPANY_NAME] - New booking",
  booking_created_push_text_tenant:
    "Service: [SERVICE_NAME], Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME].",
  booking_updated_push_subject_client: "Your booking has changed",
  booking_updated_push_text_client:
    "[SERVICE_NAME] with [COMPANY_NAME] on [BOOKING_START_DATE] at [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_updated_push_subject_tenant:
    "[COMPANY_NAME] - The booking has been changed",
  booking_updated_push_text_tenant:
    "Service: [SERVICE_NAME], Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_deleted_push_subject_client: "Your booking has been deleted",
  booking_deleted_push_text_client:
    "[SERVICE_NAME] with [COMPANY_NAME] on [BOOKING_START_DATE] at [BOOKING_START_TIME].",
  booking_deleted_push_subject_tenant:
    "[COMPANY_NAME] - The booking has been deleted",
  booking_deleted_push_text_tenant:
    "Service: [SERVICE_NAME], Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME].",
  booking_reminder_push_subject_client: "Booking reminder",
  booking_reminder_push_text_client:
    "[SERVICE_NAME] with [COMPANY_NAME] on [BOOKING_START_DATE] at [BOOKING_START_TIME].",
  booking_reminder_push_subject_tenant: "[COMPANY_NAME] - Booking reminder",
  booking_reminder_push_text_tenant:
    "Service: [SERVICE_NAME], Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME].",
  booking_created_sms_text_client:
    "Thank you for booking the [SERVICE_NAME] service with [COMPANY_NAME] on [BOOKING_START_DATE] at [BOOKING_START_TIME].",
  booking_created_sms_text_tenant:
    "[COMPANY_NAME] - New booking: [SERVICE_NAME]. Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME].",
  booking_updated_sms_text_client:
    "Your booking for [SERVICE_NAME] with [COMPANY_NAME] has been changed. On [BOOKING_START_DATE] at [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_updated_sms_text_tenant:
    "[COMPANY_NAME] - The booking has been changed: [SERVICE_NAME]. Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_deleted_sms_text_client:
    "The booking for [SERVICE_NAME] with [COMPANY_NAME] has been deleted. On [BOOKING_START_DATE] at [BOOKING_START_TIME].",
  booking_deleted_sms_text_tenant:
    "[COMPANY_NAME] - Booking deleted: [SERVICE_NAME]. Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME].",
  booking_reminder_sms_text_client:
    "We would like to remind you about your upcoming visit to [COMPANY_NAME] on [BOOKING_START_DATE] at [BOOKING_START_TIME]. Tel.: [COMPANY_PHONE].",
  booking_reminder_sms_text_tenant:
    "[COMPANY_NAME] - Reminder about your booking for the [SERVICE_NAME] service. Customer: [CUSTOMER_FULL_NAME], date: [BOOKING_START_DATE], time: [BOOKING_START_TIME].",
  account_activate_email_subject_client:
    "Confirmation of registration with [COMPANY_NAME]",
  account_activate_email_text_client:
    "<h1>Welcome to [COMPANY_NAME]</h1><p>We’re excited that you want to join us. You’re just one step away from using the tools that make it easier to access our services. Complete your registration by activating your account.</p>",
  account_activate_email_cta_button_client: "Activate your account",
  "account_password-reset_email_subject_client":
    "Your password for [COMPANY_NAME]",
  "account_password-reset_email_text_client":
    "<h1>Your password for [COMPANY_NAME]</h1><p>Click below to set your account password for [COMPANY_NAME].</p>",
  "account_password-reset_email_cta_button_client": "Set password",
  newsletter_subscribe_email_subject_client:
    "Confirmation of subscribing to the newsletter with [COMPANY_NAME]",
  newsletter_subscribe_email_text_client:
    '<h1>Newsletter Subscription Confirmation for [COMPANY_NAME]</h1><p class="cd-paragraph">Click below to confirm your subscription to the newsletter from [COMPANY_NAME]. If you received this message by mistake, you can simply ignore it.</p>',
  newsletter_subscribe_email_cta_button_client: "I confirm",
  "simple-store-product-transaction_paid_email_subject_client":
    "Thank you for purchasing [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_client":
    '<h1>This is a payment confirmation</h1><p class="cd-paragraph">Thank you for purchasing [PRODUCT_NAME].</p><p>Price: [PRODUCT_PRICE], Quantity: [PRODUCT_QUANTITY].</p><p>Additional information: [PRODUCT_DESCRIPTION]</p>',
  "simple-store-product-transaction_paid_email_subject_tenant":
    "New purchase of [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_tenant":
    '<h1>New purchase of [PRODUCT_NAME]</h1><p class="cd-paragraph">Your product [PRODUCT_NAME] was purchased by the customer [CUSTOMER_FULL_NAME].</p><p>Price: [PRODUCT_PRICE], Quantity: [PRODUCT_QUANTITY].</p><p>Additional information: [PRODUCT_DESCRIPTION]</p>',
  employee_example_name: "John",
  employee_example_surname: "Doe",
  employee_example_fullname: "John Doe",
  customer_example_name: "Jane",
  customer_example_surname: "Average",
  customer_example_fullname: "Jane Average",
  service_example_name: "ABC service",
  service_example_name_2: "Online consultation",
  phone_number_is_missing: "The phone number is missing",
  company_address_is_missing: "The company address is missing",
  sms_title: "Message",
  email_subject: "Subject",
  push_now_indicator: "now",
  variable_customer_full_name: "Customer's first and last name",
  variable_customer_id: "Customer ID",
  variable_customer_name: "Customer’s first name",
  variable_customer_surname: "Customer's last name",
  variable_customer_email: "Customer's email",
  variable_customer_phone: "Customer's phone",
  variable_employee_id: "Employee ID",
  variable_employee_name: "Employee’s first name",
  variable_employee_email: "Employee’s emal",
  variable_employee_phone: "Employee’s phone",
  variable_employee_surname: "Employee’s last name",
  variable_employee_full_name: "Employee’s first and last name",
  variable_booking_id: "Booking Id",
  variable_booking_start_date: "Booking start date",
  variable_booking_start_time: "Booking start time",
  variable_booking_end_date: "Booking end date",
  variable_booking_end_time: "Booking end time",
  variable_service_id: "Service ID",
  variable_service_name: "Service name",
  variable_booking_price: "Booking price",
  variable_company_name: "Company name",
  variable_company_phone: "Company phone",
  variable_company_address: "Company address",
  variable_booking_status: "Booking status",
  variable_booking_payment_status: "Booking payment status",
  variable_newsletter_email: "Email address",
  variable_unsubscribe_url: "Unsubscribe URL",
  variable_product_id: "Product ID",
  variable_product_name: "Product name",
  variable_product_description: "Additional product information",
  variable_product_price: "Product price",
  variable_product_quantity: "Product quantity",
  variable_product_list: "List of purchased products",
  variable_booking_location: "Location",
  variable_booking_time_zone: "Time zone",
  variable_booking_recurrence_description:
    "Description of the recurring booking",
  variable_online_meeting_url: "Online meeting URL",
  variable_booking_control_url: "Booking URL",
  variable_booking_created_by_user_id: "Creator's user ID",
  variable_booking_updated_by_user_id: "Last editor's user ID",
  variable_booking_created_by: "Creator's name",
  variable_booking_updated_by: "Last editor's name",
  variable_booking_custom_fields: "Booking form fields",
  add_variable_info_text: "Click here to add a variable",
  rename: "Rename",
  hide: "Hide",
  change_url: "Change URL",
  back: "Back",
  your_pictures: "Your gallery",
  photo_library_unsplash: "Photo library (it's free)",
  search_for_photos: "Search among millions of photos",
  search_for_photos_hint: "Unsplash only allows you to search in English.",
  photos_by_unsplash: "Photos by Unsplash",
  photo_by_on_unsplash: "Photo by @{name} on Unsplash",
  unsplash_photo_added_to_gallery:
    "The photo is now available in your gallery.",
  for_example_unsplash: "e.g. flowers",
  new_website_builder_title: "New website builder",
  website_builder_banner_text:
    "It's available now! Create your website in the new builder!",
  check_now: "Check now",
  // Color picker
  cp_alert_p1: "The contrast is too low!",
  cp_alert_p2: "It will be challenging to see the element.",
  // Website builder
  wb_website_builder: "Website builder",
  wb_move_up: "Move up",
  wb_move_down: "Move down",
  wb_auto_save_info: "Changes are saved automatically",
  wb_pages: "Subpages",
  wb_add_new_section: "Add section",
  wb_select_new_section: "Select section",
  wb_add_new_section_info: "Select a section from the menu on the left",
  wb_add_new_page: "Add subpage",
  wb_add_page_dialog_type_page_name_label: "Name, e.g. Services",
  wb_add_page_dialog_type_page_name_blog_label: "Name e.g. Blog",
  wb_add_page_dialog_type_page_description:
    "Description (visible to search engines like Google)",
  wb_add_page_dialog_type_page_description_hint:
    "Enter a unique, short description of the contents of your page. This description will be visible in search engine results, such as Google, under the title of your page.",
  wb_add_page_dialog_type_page_path_label: "Url path, e.g. services",
  wb_add_page_dialog_type_page_path_blog_label:
    "Address after the domain e.g. blog",
  wb_add_page_dialog_type_main_page: "Home page",
  wb_add_page_dialog_type_page: "Subpage",
  wb_add_page_dialog_type_redirect: "Redirect (external link)",
  wb_add_page_dialog_type_blog_handy: "Blog",
  wb_add_page_dialog_type_redirect_url: "Redirect URL",
  wb_add_page_dialog_type_blog_handy_id:
    "BlogHandy Id e.g. 609wl61ZYL0ArTAFR23",
  wb_blog_handy_description:
    "BlogHandy is an external platform that allows you to easily run a blog. BlogHandy can be easily integrated with your website in Calendesk.",
  wb_blog_handy_info_1:
    "To add a blog to your Calendesk page, follow the instructions below:",
  wb_blog_handy_info_2: "Click below and register on the BlogHandy platform.",
  wb_blog_handy_info_3:
    "Copy the Blog identifier from your BlogHandy account and paste it into the field below.",
  wb_blog_handy_info_4:
    "Congratulations! 🎉 Every blog post published on BlogHandy will automatically appear on your Calendesk page.",
  wb_blog_handy_signup_button_title: "Sign up for BlogHandy",
  wb_general: "General",
  wb_style: "Style",
  wb_section: "Page section",
  wb_color_primary: "Buttons and important elements",
  wb_color_bg: "First background",
  wb_color_text: "Text on the first background",
  wb_color_bg_2: "Second background",
  wb_color_text_2: "Text on the second background",
  wb_google_tag_id: "Google Tag ID",
  wb_website_description: "Website description for robots (SEO)",
  wb_title: "Header",
  wb_text: "Content",
  wb_subtitle: "Subtitle",
  wb_description: "Description",
  wb_image: "Picture",
  wb_image_1: "Picture 1",
  wb_image_2: "Picture 2",
  wb_image_3: "Picture 3",
  wb_image_4: "Picture 4",
  wb_background: "Background",
  wb_category_section_navbar_1: "Navbar with logo",
  wb_category_section_navbar_2: "Navbar without logo",
  wb_category_section_footer: "Footer",
  wb_category_section_employees: "Employees",
  wb_category_section_services: "Services",
  wb_category_section_booking: "Booking visits",
  wb_category_section_about_us: "About us",
  wb_category_section_feature: "Universal",
  wb_category_section_review: "Reviews",
  wb_category_section_other: "Other",
  wb_category_section_header: "Header",
  wb_category_section_newsletter: "Newsletter",
  wb_category_section_form: "Contact form",
  wb_category_section_cta: "Call To Action",
  wb_decrease_section: "Decrease",
  wb_increase_section: "Increase",
  wb_company_name: "Company name",
  wb_login_modal: "Sign in",
  wb_login_subtitle: "Subtitle",
  wb_sign_up: "Create an account",
  wb_login: "Sign in",
  wb_sign_up_modal: "Registration",
  wb_menu_bookings: "Bookings",
  wb_menu_payments: "Payment",
  wb_menu_settings: "Settings",
  wb_menu_logout: "Sign out",
  wb_forgotten_password_modal: "Password reminder",
  wb_forgotten_password_confirmation_modal: "Email confirmation",
  wb_forgotten_password_title: "Title",
  wb_forgotten_password_subtitle: "Subtitle",
  wb_submit_button: "Approval button",
  wb_forgotten_password_modal_confirmation_title: "Title",
  wb_forgotten_password_modal_confirmation_subtitle: "Subtitle",
  wb_company_address: "Address",
  wb_company_email: "Email address",
  wb_company_phone: "Phone",
  wb_menu_title: "Menu title",
  wb_account_title: "Profile",
  wb_signup: "Sign up",
  wb_profile: "Bookings",
  wb_settings: "Settings",
  wb_company_slogan: "Slogan",
  wb_service_label: "Service label",
  wb_employee_label: "Employee label",
  wb_employee_info_label: "Text above employees' field",
  wb_booking_button: "Booking button",
  wb_page_list: "Edit menu",
  wb_menu_column_1: "Menu 1",
  wb_menu_column_2: "Menu 2",
  wb_publish_confirm_dialog_title: "Publish the page?",
  wb_publish_confirm_dialog_text:
    "The website will be visible on the Internet within a minute.",
  wb_publish_success_text:
    "Congratulations! Your website will be available on the Internet in a moment.",
  wb_more_pages_button: "Remaining pages",
  wb_sign_up_terms_info:
    "Should we display information about the Terms and Conditions?",
  wb_select_data_label: "Select date",
  wb_edit_page: "Edit page",
  wb_remove_page_confirm_dialog_title: "Delete the page?",
  wb_remove_page_confirm_dialog_text: "The page will be deleted.",
  wb_remove_page_success_text: "The page has been deleted.",
  wb_remove_confirm_dialog_title: "Are you sure?",
  wb_remove_confirm_dialog_text: "This change cannot be undone!",
  wb_remove_success_text: "Deletion completed successfully",
  wb_employees_title: "Our team",
  wb_employees_no_data:
    "Add employees in the Calendesk panel to see a preview.",
  wb_services_no_data: "Add services in the Calendesk panel to see a preview.",
  wb_employee_list: "Employees",
  wb_service_list: "Services",
  wb_items_selector_info: "Click below to add items",
  wb_change: "Replace",
  wb_replace_navbar: "Replace the header",
  wb_replace_footer: "Replace the footer",
  wb_main_pages_number: "Number of main tabs",
  wb_button: "Button",
  wb_domain_example: "E.g. mycompany.com",
  wb_no_domain_info: "You don't have a domain set up",
  wb_book_online: "Sign up button",
  wb_button_title: "Button",
  wb_select_subpage: "Subpage",
  wb_features: "Attributes",
  wb_add_button: "Add a button",
  wb_new_button: "New button",
  wb_button_edit_menu_redirect: "Select the redirection",
  wb_button_edit_menu_style: "Set the style and size",
  wb_button_outlined: "Outlined",
  wb_button_rounded: "Rounded",
  wb_button_size: "Size",
  wb_button_dark: "Dark",
  wb_redirect: "Redirect",
  wb_add_sections_feature: "Add an item",
  wb_dummy_text_about_us: "About us",
  wb_dummy_text_service: "Service",
  wb_dummy_text_select_service: "Select a service",
  wb_dummy_text_select_employee: "Select a person",
  wb_dummy_selected_services: "Selected services",
  wb_dummy_text_select_date: "Select a date",
  wb_dummy_text_select_date_and_hour: "Please pick your date and time",
  wb_dummy_text_services: "Our services",
  wb_dummy_text_book_now: "Book now",
  wb_dummy_text_employees: "Our team",
  wb_dummy_text_more: "See more",
  wb_dummy_text_buy_now: "Buy now",
  wb_dummy_text_regulations: "Terms and Conditions",
  wb_dummy_text_privacy_policy: "Privacy Policy",
  wb_dummy_text_reviews_title: "Feedback from our customers",
  wb_dummy_text_reviews_full_name: "John Doe",
  wb_dummy_text_review_example:
    "This place is amazing, I have been using their services for a long time and they still surprise me! I definitely recommend :)",
  wb_dummy_text_booking_title:
    "Book an appointment with us in a few minutes – without leaving home and 100% online.",
  wb_dummy_text_1:
    "A modern place located in the center of Warsaw. We are always there for you and only the best of the best work with us. We constantly participate in training and gain knowledge that we can later share with you. You can trust us for we use cutting-edge solutions. You can make an appointment online and have a modern mobile app at your disposal to receive the most important information from us.",
  wb_dummy_text_2: "Explore the possibilities offered by our services",
  wb_dummy_text_3: "What do we offer?",
  wb_dummy_text_4: "What would you like to talk about?",
  wb_dummy_text_5: "Enter your name and surname",
  wb_dummy_text_6:
    "This is an example of a service description. You can set it up in the Calendesk admin panel while editing services. Service descriptions can be different for each service.",
  wb_dummy_text_title: "Sample text",
  wb_dummy_subject_title: "Message from the form on the website",
  wb_dummy_form_sent_success_title:
    "Thank you, the form was submitted correctly.",
  wb_dummy_form_sent_success_description:
    "We will contact you as soon as possible.",
  wb_dummy_text_newsletter_1:
    "Information about new services and products direct to your email",
  wb_dummy_text_newsletter_2: "Subscribe to the newsletter and stay up to date",
  wb_dummy_text_newsletter_3: "Subscribe",
  wb_dummy_text_newsletter_4: "Email address",
  wb_dummy_text_newsletter_5:
    "Thank you! We have sent an activation link to your email address.",
  wb_dummy_text_newsletter_6: "More than you think",
  wb_regulations_info:
    "You can edit the content of the Terms and Conditions in the Calendesk panel in the Settings / Your Company tab.",
  wb_privacy_policy_info:
    "You can edit the content of the Privacy Policy in the Calendesk panel in the Settings / Your Company tab.",
  wb_regulations_page: "Terms and Conditions subpage",
  wb_privacy_policy_page: "Privacy Policy subpage",
  wb_login_enabled: "Login enabled",
  wb_booking_confirmation: "Booking confirmation",
  wb_booking_created_summary: "Confirmation of the message that was sent",
  wb_booking_created_payment_summary: "Payment information",
  wb_creator_chat_1:
    "Hello &#128075;. I am your virtual assistant for creating websites. I'll help you set up your website in minutes.",
  wb_creator_chat_2: "Choose the type of your business",
  wb_creator_chat_3:
    "Thanks! The first step was easy &#128588;. It's time to choose the elements that will appear on your new website.",
  wb_creator_chat_4:
    "Choose the style that we will apply to the website. You can easily change the colors later.",
  wb_creator_chat_5:
    "It's almost done! You can have several page drafts and change them as needed &#129321;. How do you want to name the current draft?",
  wb_creator_chat_6:
    "It's done &#128079;! You did it great. I'm getting the page ready, give me a moment... &#128640; &#128640;",
  wb_creator_chat_6a: "It will be amazing &#129321;",
  wb_creator_chat_6b: "I'm almost done ...",
  wb_creator_chat_6c: "Just a few more lines of code, and it's done ...",
  wb_select_draft_name: "How do you want to name the current page draft?",
  wb_draft_name_text_field_label: "Website name",
  wb_chat_with_us: "Chat with us",
  wb_selected_n_items: "Selected items: @{amount}.",
  wb_business_1: "Hairdressing salon",
  wb_business_2: "Spa",
  wb_business_3: "Beauty salon",
  wb_business_4: "Dietitian",
  wb_business_5: "Private lessons",
  wb_business_6: "Child care",
  wb_business_7: "Business events",
  wb_business_8: "City Hall",
  wb_business_9: "Financial services",
  wb_business_10: "Escape room",
  wb_business_11: "Legal services",
  wb_business_12: "Medicine",
  wb_business_13: "Driving school",
  wb_business_14: "Photographer",
  wb_business_15: "Psychologist",
  wb_business_16: "Consulting",
  wb_business_17: "Coaching",
  wb_business_18: "Animal care",
  wb_business_19: "Architecture/Interior design",
  wb_business_20: "Cleaning services",
  wb_business_21: "Sport",
  wb_business_22: "Personal trainer",
  wb_business_23: "Gym",
  wb_business_24: "Fitness",
  wb_business_25: "Yoga",
  wb_business_26: "Tennis club",
  wb_business_27: "Meeting facilities",
  wb_business_28: "Restaurant",
  wb_business_29: "Physiotherapy",
  wb_business_30: "Other",
  wb_website_element_1: "Customer login and sign up",
  wb_website_element_2: "Online bookings",
  wb_website_element_3: "Services",
  wb_website_element_4: "About us",
  wb_website_element_5: "Team",
  wb_website_element_6: "Terms and Conditions and Privacy Policy",
  wb_website_element_7: "Photo library",
  wb_website_element_8: "Newsletter",
  wb_website_element_tooltip_1:
    "We will enable on your website a module for your customers to create an account and log in.",
  wb_website_element_tooltip_2:
    "We will add a section with the possibility of making online bookings.",
  wb_website_element_tooltip_3:
    "We will add a section and a subpage with your services.",
  wb_website_element_tooltip_4:
    "We will add a section with information about your company.",
  wb_website_element_tooltip_5:
    "We will add a section and a subpage with your employees.",
  wb_website_element_tooltip_6:
    "We will add a subpage with the Terms and Conditions and the Privacy Policy. We will take everything from the Calendesk panel.",
  wb_website_element_tooltip_7: "We will add a section with a photo gallery.",
  wb_website_element_tooltip_8: "We will add a newsletter section.",
  wb_website_style_1: "Sea Storm",
  wb_website_style_2: "Sea Steel",
  wb_website_style_3: "Pastel Powder",
  wb_website_style_4: "Hazy Poppy",
  wb_website_style_5: "Forest World",
  wb_website_style_6: "Honey Twilight",
  wb_website_style_7: "Sunny Smile",
  wb_website_style_8: "Red Kiss",
  wb_website_style_9: "Healthy Green",
  wb_website_style_10: "Rainy Cloud",
  wb_website_style_11: "Berries in Chocolate",
  wb_website_style_12: "Orange Dust",
  wb_draft_name_example: "My website - @{template}",
  wb_default_login_text: "You must be signed in to book an appointment.",
  wb_default_forgotten_password_title:
    "Everyone sometimes forgets their password.",
  wb_default_forgotten_password_subtitle:
    "Enter the email address used to sign up to receive instructions for changing your password.",
  wb_default_forgotten_password_modal_confirmation_title: "Check your inbox",
  wb_default_forgotten_password_modal_confirmation_subtitle:
    "If the email address exists in the system, we have just sent you a message with instructions on how to change your password.",
  wb_default_booking_created_summary:
    "We have sent the booking confirmation to your email address.",
  wb_default_address: "4 Theatre Ave. Forest Hills, NY 11375",
  wb_default_email: "john@calendesk.com",
  wb_default_product_name: "Sample product",
  wb_default_product_description: "This is additional product information.",
  wb_default_phone: "+1 206 200 2264",
  wb_default_menu_title: "Menu",
  wb_default_profile_title: "Profile",
  wb_main_page_name: "Home page",
  wb_main_page_url: "home-page",
  wb_employees_page_name: "About us",
  wb_team_page_name: "Our team",
  wb_employees_page_url: "about-us",
  wb_team_page_url: "our-team",
  wb_services_page_name: "Services",
  wb_services_page_name_2: "Оffers",
  wb_services_page_url: "services",
  wb_services_page_url_2: "offers",
  wb_regulations_page_name: "Terms and Conditions",
  wb_regulations_page_url: "terms-and-conditions",
  wb_privacy_policy_page_name: "Privacy Policy",
  wb_privacy_policy_page_url: "privacy-policy",
  wb_dark_mode: "Dark mode",
  wb_facebook: "Facebook URL",
  wb_instagram: "Instagram URL",
  wb_twitter: "Twitter URL",
  wb_youtube: "YouTube URL",
  wb_section_missing_question:
    "Missing a section? Write to us, we will make it for you!",
  wb_employees_select_section_info:
    "You can edit the descriptions of employees and their photos in the Calendesk panel.",
  wb_edit_employees_button_title: "Edit employees",
  wb_services_select_section_info:
    "You can edit the descriptions of services and their photos in the Calendesk panel.",
  wb_edit_services_button_title: "Edit services",
  wb_reload_button_title: "Refresh",
  wb_category_section_photo_gallery: "Photo library",
  wb_hide_arrows: "Hide arrows",
  wb_hide_delimiters: "Hide delimiters",
  wb_interval: "Speed of changes in milliseconds",
  wb_cycle: "Automatic switching",
  wb_invert_colors: "Invert colors",
  wb_website_disabled_success:
    "The website has been disabled and will no longer be visible on the Internet within a few minutes.",
  wb_set_as_main_website: "Set as the main website",
  wb_set_as_main_website_success: "The main website has been set correctly.",
  wb_main_website: "The main website",
  new_content_available:
    'There is a new page update, please click "Refresh" to reload the page.',
  refresh_button_title: "Refresh",
  wb_newsletter_input_placeholder: "Hint",
  wb_newsletter_success_message: "Message about sending the link",
  newsletter_delete_success: "Selected records have been deleted correctly",
  remove_newsletter_record: "Delete email",
  remove_newsletter_records: "Delete emails",
  verified_at: "Verified",
  email_is_valid: "Email confirmed",
  email_is_not_valid: "Email not confirmed",
  newsletters: "Newsletter",
  push: "Push",
  push_tooltip: "The user uses the mobile application",
  send_customer_notification_title: "Send a message to the client",
  send_customers_notification_title: "Send a message to clients",
  send_employee_notification_title: "Send a message to the employee",
  send_employees_notification_title: "Send a message to employees",
  send_customer_notification_number_info: "Number of recipients",
  notification_type_1: "Email",
  notification_type_2: "SMS",
  notification_type_3: "Push notification",
  notification_type_title: "Notification type",
  notification_category_title: "Notification category",
  notification_subject: "Title",
  notification_content: "Content",
  send: "Send",
  send_customer_notification_confirm_title:
    "Are you sure you want to send a message to the selected people?",
  send_customer_notification_confirm_info:
    'You are sending the message type "@{type}". Number of recipients: @{amount}.',
  download_csv_title: "Download CSV",
  download_vcf_title: "Download VCF (vCard)",
  download_xlsx_title: "Download XLSX (Microsoft Excel)",
  download_xls_title: "Download XLS (Microsoft Excel)",
  download_ods_title: "Download ODS (Open Office, LibreOffice)",
  download_html_title: "Download HTML",
  customer_notifications: "History",
  customer_type: "Recipient",
  customer_type_1: "Customer",
  customer_type_2: "Newsletter",
  customer_type_3: "Employee",
  customer_type_4: "External",
  customer_type_5: "Company",
  recipients_count_title: "Number of recipients",
  display_customer_notification_title: "Message preview",
  expand: "Expand",
  collapse: "Collapse",
  reverse_charge_info: "VAT reverse charge applies to your invoices.",
  reverse_charge_info_2: "VAT reverse charge applies.",
  employees_premium_banner_title: "Limit reached",
  employees_premium_banner_description:
    "Upgrade to add an unlimited number of employees.",
  users_premium_banner_title: "Limit reached",
  users_premium_banner_description:
    "Upgrade to add an unlimited number of customers.",
  google_calendar: "Google calendar",
  google_meet: "Google Meet",
  google_meet_description:
    "Set your service locations to Google Meet and let Calendesk create a unique Google Meet link for your bookings. The link will be sent to you and your client automatically.",
  google_calendar_connect_info:
    "Click below to link your Google Calendar to the Calendesk calendar. After the correct connection, all your appointments and their changes will be sent to the Google Calendar. Remember, the calendar works only one way. If you change an appointment in Google Calendar, this change will not be visible in Calendesk. Always make all changes via Calendesk.",
  google_calendar_disconnect_info:
    "Synchronization with Google Calendar is active. Click below to disconnect.",
  disconnect_button_title: "Disconnect",
  plugin_only_for_employees_info:
    "This add-on only works with an employee's account. You need to create an employee account to be able to accept bookings.",
  plugin_not_active_employee_info:
    "The add-on is not active. Please get in touch with the Administrator to enable the add-on.",
  google_calendar_auth_success_title: "Google authorization successful",
  google_calendar_disconnect_dialog_title: "Disconnect Google Calendar",
  google_calendar_disconnect_dialog_info:
    "Are you sure you want to disconnect your Google Calendar? We will no longer send your future Calendesk bookings and blackout dates to your Google Calendar.",
  google_calendar_disconnect_success_message:
    "The calendar has been properly disconnected.",
  buy: "Purchase",
  on_time_payment: "One-time fee",
  plan_expired_title: "Your current plan has expired",
  building_plans_title:
    "We’ll turn on all the features you need &#128640;&#128640;!",
  building_plans_subtitle: "This will not take longer than a few seconds...",
  go_to_summary: "Go to the summary",
  cd_base_standard: "Calendesk Standard",
  cd_base_pro: "Calendesk Pro",
  cd_base_enterprise: "Calendesk Enterprise",
  plans_description_standard:
    "Take advantage of Calendesk to improve your business.",
  plans_description_pro: "For the most demanding people, focused on growth.",
  plans_description_enterprise:
    "All the flexibility to match your growth. Contact us to find out more.",
  enterprise_price_list_info: "Individual contract with annual billing",
  plans_billed_annually: "billed annually",
  plans_or: "or",
  plans_month_to_month_payment: "billed monthly",
  plans_featured: "Most popular",
  plans_feature_name_1: "Your website address",
  plans_feature_lite_1: "Basic subdomain: calendesk.net",
  plans_feature_lite_1_tooltip:
    "It is not possible to set your own domain, your website address will look like this: www.yourName.calendesk.net.",
  plans_feature_standard_1: "Own domain",
  plans_feature_standard_1_tooltip:
    "The possibility to set any web address when you purchase a domain on an external hosting service.",
  plans_feature_pro_1: "Own domain",
  plans_feature_pro_1_tooltip:
    "The possibility to set any web address when you purchase a domain on an external hosting service.",
  plans_feature_name_2: "Amount of website pages",
  plans_feature_standard_2: "Maximum of 1 website",
  plans_feature_standard_2_tooltip:
    "The ability to create 1 dedicated page with unlimited subpages for your business.",
  plans_feature_pro_2: "Maximum of 3 websites",
  plans_feature_pro_2_tooltip:
    "The ability to create 3 dedicated pages with unlimited subpages for the different services you offer (e.g., under SEO and SEM).",
  plans_feature_enterprise_2: "Unlimited",
  plans_feature_enterprise_2_tooltip:
    "The ability to create as many pages as you like for different services and different customers – for example, pages for individual customers, for business, advertising a specific product or service, Facebook pages and special search engine pages (SEO and SEM).",
  plans_feature_name_3: "Website Builder",
  plans_feature_standard_3: "Yes",
  plans_feature_standard_3_tooltip:
    "The possibility to create as many sections as you like on a page, access to a wide range of images, and the possibility to personalize the website.",
  plans_feature_pro_3: "Yes",
  plans_feature_pro_3_tooltip:
    "The possibility to create as many sections as you like on a page, access to a wide range of images, and the possibility to personalize the website.",
  plans_feature_enterprise_3: "Yes",
  plans_feature_enterprise_3_tooltip:
    "The possibility to create as many sections as you like on a page, access to a wide range of images, and the possibility to personalize the website.",
  plans_feature_name_4: "Mobile app for customers",
  plans_feature_standard_4: "Yes",
  plans_feature_standard_4_tooltip:
    "The ability to create a simple mobile app for your customers’ phones – make it as easy as possible for them to reach you and use your services.",
  plans_feature_pro_4: "Yes",
  plans_feature_pro_4_tooltip:
    "The ability to create a simple mobile application or an advanced, personalized version – fully tailored to your needs.",
  plans_feature_enterprise_4: "Yes",
  plans_feature_enterprise_4_tooltip:
    "The ability to create a simple mobile application or an advanced, personalized version – fully tailored to your needs.",
  plans_feature_name_5: "Email notifications",
  plans_feature_standard_5: "500 messages per month",
  plans_feature_standard_5_tooltip:
    "Send your customers booking emails (confirm acceptance of the appointment, change or cancellation), transaction emails, notifications and special offers.",
  plans_feature_pro_5: "1,000 messages per month",
  plans_feature_pro_5_tooltip:
    "Send your customers booking emails (confirm acceptance of the appointment, change or cancellation), transaction emails, notifications and special offers.",
  plans_feature_enterprise_5: "Unlimited",
  plans_feature_enterprise_5_tooltip:
    "Send your customers booking emails (confirm acceptance of the appointment, change or cancellation), transaction emails, notifications and special offers.",
  plans_feature_name_6: "Expanded email package",
  plans_feature_standard_6: "Yes",
  plans_feature_standard_6_tooltip:
    "The possibility to purchase any amount for the price of $0.03 per email.",
  plans_feature_pro_6: "Yes",
  plans_feature_pro_6_tooltip:
    "The possibility to purchase any amount for the price of $0.01 per email.",
  plans_feature_enterprise_6: "Yes",
  plans_feature_enterprise_6_tooltip:
    "The possibility to purchase any amount at an individual price.",
  plans_feature_name_7: "SMS notifications",
  plans_feature_standard_7: "50 messages per month",
  plans_feature_standard_7_tooltip:
    "Send SMS messages to your customers – confirm booking, change or cancel an appointment, inform about promotions, special offers and discounts.",
  plans_feature_pro_7: "100 messages per month",
  plans_feature_pro_7_tooltip:
    "Send SMS messages to your customers – confirm booking, change or cancel an appointment, inform about promotions, special offers and discounts.",
  plans_feature_enterprise_7: "Unlimited",
  plans_feature_enterprise_7_tooltip:
    "Send SMS messages to your customers – confirm booking, change or cancel an appointment, inform about promotions, special offers and discounts.",
  plans_feature_name_8: "Expanded SMS packet",
  plans_feature_standard_8: "Yes",
  plans_feature_standard_8_tooltip:
    "The possibility to purchase any amount for the price of $0.08 per SMS + a special package of 200 SMSes per month for the price of $9.99.",
  plans_feature_pro_8: "Yes",
  plans_feature_pro_8_tooltip:
    "The possibility to purchase any amount for the price of $0.07 per SMS + a PREMIUM package of 250 SMSes per month for the price of $9.99.",
  plans_feature_enterprise_8: "Yes",
  plans_feature_enterprise_8_tooltip:
    "The possibility to purchase any amount at an individual price.",
  plans_feature_name_10: "Payments and subscriptions",
  plans_feature_standard_10: "Yes",
  plans_feature_standard_10_tooltip:
    "Make payments online – quickly, comfortably and securely. Don't worry about giving back change in cash transactions and empty bookings for which you don't earn anything.",
  plans_feature_pro_10: "Yes",
  plans_feature_pro_10_tooltip:
    "Make payments online – quickly, comfortably and securely. Don't worry about giving back change in cash transactions and empty bookings for which you don't earn anything.",
  plans_feature_enterprise_10: "Yes",
  plans_feature_enterprise_10_tooltip:
    "Make payments online – quickly, comfortably and securely. Don't worry about giving back change in cash transactions and empty bookings for which you don't earn anything.",
  plans_feature_name_11: "Booking system",
  plans_feature_standard_11: "Yes",
  plans_feature_standard_11_tooltip:
    "Full online booking option, including the ability for the customer to annotate and leave a comment.",
  plans_feature_pro_11: "Yes",
  plans_feature_pro_11_tooltip:
    "Full online booking option, including the ability for the customer to annotate and leave a comment.",
  plans_feature_enterprise_11: "Yes",
  plans_feature_enterprise_11_tooltip:
    "Full online booking option, including the ability for the customer to annotate and leave a comment.",
  plans_feature_name_12: "Google Calendar integration",
  plans_feature_standard_12: "Yes",
  plans_feature_standard_12_tooltip:
    "Connect your services with Google Calendar – export all visits, orders and meetings to Google Calendar. Make them visible to your employees and customers. Make their lives as easy as possible and increase your business effectiveness.",
  plans_feature_pro_12: "Yes",
  plans_feature_pro_12_tooltip:
    "Connect your services with Google Calendar – export all visits, orders and meetings to Google Calendar. Make them visible to your employees and customers. Make their lives as easy as possible and increase your business effectiveness.",
  plans_feature_enterprise_12: "Yes",
  plans_feature_enterprise_12_tooltip:
    "Connect your services with Google Calendar – export all visits, orders and meetings to Google Calendar. Make them visible to your employees and customers. Make their lives as easy as possible and increase your business effectiveness.",
  plans_feature_name_14: "Newsletter",
  plans_feature_standard_14: "Yes",
  plans_feature_standard_14_tooltip:
    "The possibility to create a simple customer newsletter – reminding them of your services, offering bonuses, discounts, promotions and additional services.",
  plans_feature_pro_14: "Yes",
  plans_feature_pro_14_tooltip:
    "The possibility to create a simple customer newsletter – reminding them of your services, offering bonuses, discounts, promotions and additional services.",
  plans_feature_enterprise_14: "Yes",
  plans_feature_enterprise_14_tooltip:
    "The possibility to create a simple customer newsletter – reminding them of your services, offering bonuses, discounts, promotions and additional services.",
  plans_feature_name_18: "Number of bookings",
  plans_feature_standard_18: "750 bookings per month",
  plans_feature_standard_18_tooltip: "Handle up to 750 bookings per month.",
  plans_feature_pro_18: "2,500 bookings per month",
  plans_feature_pro_18_tooltip: "Handle up to 2,500 bookings per month.",
  plans_feature_enterprise_18: "Unlimited",
  plans_feature_enterprise_18_tooltip: "Unlimited monthly bookings.",
  plans_feature_name_19: "Number of employees",
  plans_feature_standard_19: "Up to 5 employees",
  plans_feature_standard_19_tooltip:
    "Limited to 5 application users – ability to establish basic roles and access levels.",
  plans_feature_pro_19: "Up to 15 employees",
  plans_feature_pro_19_tooltip:
    "Limited to 15 application users – ability to establish basic roles and access levels.",
  plans_feature_enterprise_19: "Unlimited",
  plans_feature_enterprise_19_tooltip:
    "Unlimited application users – ability to establish basic roles and access levels.",
  plans_feature_name_20: "Privacy Policy and Terms and Conditions",
  plans_feature_standard_20: "Yes",
  plans_feature_standard_20_tooltip:
    "Prepared by lawyers and can be generated within the application.",
  plans_feature_pro_20: "Yes",
  plans_feature_pro_20_tooltip:
    "Prepared by lawyers and can be generated within the application.",
  plans_feature_enterprise_20: "Yes",
  plans_feature_enterprise_20_tooltip:
    "Prepared by lawyers and can be generated within the application.",
  plans_feature_name_21: "Google Meet Integration",
  plans_feature_standard_21: "Yes",
  plans_feature_standard_21_tooltip:
    "Generate Google Meet links right after a booking.",
  plans_feature_pro_21: "Yes",
  plans_feature_pro_21_tooltip:
    "Generate Google Meet links right after a booking.",
  plans_feature_enterprise_21: "Yes",
  plans_feature_enterprise_21_tooltip:
    "Generate Google Meet links right after a booking.",
  plans_feature_name_22: "InvoiceOcean Integration",
  plans_feature_standard_22: "Yes",
  plans_feature_standard_22_tooltip:
    "Issue invoices for your customers right after a payment.",
  plans_feature_pro_22: "Yes",
  plans_feature_pro_22_tooltip:
    "Issue invoices for your customers right after a payment.",
  plans_feature_enterprise_22: "Yes",
  plans_feature_enterprise_22_tooltip:
    "Issue invoices for your customers right after a payment.",
  plans_feature_name_23: "Zoom integration",
  plans_feature_standard_23: "Yes",
  plans_feature_standard_23_tooltip:
    "Generate Zoom links right after a booking.",
  plans_feature_pro_23: "Yes",
  plans_feature_pro_23_tooltip: "Generate Zoom links right after a booking.",
  plans_feature_enterprise_23: "Yes",
  plans_feature_enterprise_23_tooltip:
    "Generate Zoom links right after a booking.",
  plans_feature_name_24: "Zapier Integration",
  plans_feature_standard_24: "No",
  plans_feature_standard_24_tooltip:
    "Option available on the Pro plan and above.",
  plans_feature_pro_24: "Yes",
  plans_feature_pro_24_tooltip: "Connect your apps and automate workflows.",
  plans_feature_enterprise_24: "Yes",
  plans_feature_enterprise_24_tooltip:
    "Connect your apps and automate workflows.",
  plans_feature_name_25: "API Access",
  plans_feature_standard_25: "No",
  plans_feature_standard_25_tooltip:
    "Option available on the Pro plan and above.",
  plans_feature_pro_25: "Yes",
  plans_feature_pro_25_tooltip:
    "Use and exchange Calendesk data with any applications that you work with.",
  plans_feature_enterprise_25: "Yes",
  plans_feature_enterprise_25_tooltip:
    "Use and exchange Calendesk data with any applications that you work with.",
  plans_feature_name_26: "Booking widget",
  plans_feature_standard_26: "Yes",
  plans_feature_standard_26_tooltip:
    "Possibility to use Calendesk Booking System on your own website.",
  plans_feature_pro_26: "Yes",
  plans_feature_pro_26_tooltip:
    "Possibility to use Calendesk Booking System on your own website.",
  plans_feature_enterprise_26: "Yes",
  plans_feature_enterprise_26_tooltip:
    "Possibility to use Calendesk Booking System on your own website.",
  plans_feature_name_27: "Monthly unique visitors",
  plans_feature_standard_27: "5,000 unique visits per month",
  plans_feature_standard_27_tooltip:
    "5,000 monthly unique visitors to your website or calendar.",
  plans_feature_pro_27: "10,000 unique visits per month",
  plans_feature_pro_27_tooltip:
    "10,000 monthly unique visitors to your website or calendar.",
  plans_feature_enterprise_27: "Unlimited",
  plans_feature_enterprise_27_tooltip:
    "Unlimited monthly unique visitors to your website or calendar.",
  plans_feature_name_28: "Microsoft Outlook integration",
  plans_feature_standard_28: "Yes",
  plans_feature_pro_28: "Yes",
  plans_feature_standard_28_tooltip:
    "Connect your services with Outlook – export all visits, orders and meetings to Outlook. Make them visible to your employees and customers. Make their lives as easy as possible and increase your business effectiveness.",
  plans_feature_pro_28_tooltip:
    "Connect your services with Outlook – export all visits, orders and meetings to Outlook. Make them visible to your employees and customers. Make their lives as easy as possible and increase your business effectiveness.",
  plans_feature_enterprise_28: "Yes",
  plans_feature_enterprise_28_tooltip:
    "Connect your services with Outlook – export all visits, orders and meetings to Outlook. Make them visible to your employees and customers. Make their lives as easy as possible and increase your business effectiveness.",
  plans_feature_name_29: "Microsoft Teams integration",
  plans_feature_standard_29: "Yes",
  plans_feature_standard_29_tooltip:
    "Generate Microsoft Teams meeting links right after a booking.",
  plans_feature_pro_29: "Yes",
  plans_feature_pro_29_tooltip:
    "Generate Microsoft Teams meeting links right after a booking.",
  plans_feature_enterprise_29: "Yes",
  plans_feature_enterprise_29_tooltip:
    "Generate Microsoft Teams meeting links right after a booking.",
  plans_feature_name_30: "Skype Integration",
  plans_feature_standard_30: "Yes",
  plans_feature_standard_30_tooltip:
    "Generate Skype meeting links right after a booking.",
  plans_feature_pro_30: "Yes",
  plans_feature_pro_30_tooltip:
    "Generate Skype meeting links right after a booking.",
  plans_feature_enterprise_30: "Yes",
  plans_feature_enterprise_30_tooltip:
    "Generate Skype meeting links right after a booking.",
  plans_feature_name_31: "WhatsApp integration",
  plans_feature_standard_31: "Yes",
  plans_feature_standard_31_tooltip:
    "Generate WhatsApp meeting links right after a booking.",
  plans_feature_pro_31: "Yes",
  plans_feature_pro_31_tooltip:
    "Generate WhatsApp meeting links right after a booking.",
  plans_feature_enterprise_31: "Yes",
  plans_feature_enterprise_31_tooltip:
    "Generate WhatsApp meeting links right after a booking.",
  plans_feature_name_32: "Advanced reminders",
  plans_feature_standard_32: "No",
  plans_feature_standard_32_tooltip:
    "Basic reminders in the form of emails, SMS, and push notifications, which can be set at any time.",
  plans_feature_pro_32: "Yes",
  plans_feature_pro_32_tooltip:
    "Advanced and precise reminders, allowing specification of what reminders are sent, to whom, and when.",
  plans_feature_enterprise_32: "Yes",
  plans_feature_enterprise_32_tooltip:
    "Advanced and precise reminders, allowing specification of what reminders are sent, to whom, and when.",
  plans_trial_faq_2_title: "Can I unsubscribe from Calendesk at any time?",
  plans_trial_faq_2_description_1:
    "We'd be very sorry to see you go, but of course <b>you can cancel a subscription whenever you want</b>. Calendesk does not tie you down to any long-term contracts. You can use Calendesk by paying us monthly or annually, with a 20% discount on annual payments.",
  plans_trial_faq_3_title: "Can I change my subscription plan at any time?",
  plans_trial_faq_3_description_1:
    "Yes, of course, <b>you can migrate between subscription plans at any time</b>. Our payment operator will calculate the amounts so that you do not lose anything when switching. If you upgrade during the billing period, we will only charge you for the actual amount of days used. If you downgrade during the billing period, we will use the remaining amount for future payments or refund you.",
  plans_trial_faq_4_title:
    "What happens after the contract period ends? Will the application automatically extend and charge an additional fee for the entire period?",
  plans_trial_faq_4_description_1:
    "Yes, the selected plan will be automatically renewed. If you do not want your plan to renew for the next time period, you can cancel it at any time in the “Accounts and payments” tab.",
  plans_trial_faq_5_title:
    "Will I lose my account and data if I forget to extend my Calendesk usage period?",
  plans_trial_faq_5_description_1:
    "Your data will continue to be available in our system for some time, so you can easily regain access to your account and the project you have created when resubscribing.",
  plans_trial_faq_5_description_2:
    "After the contract period has expired and an account has been blocked even for a few days, you still have the opportunity to recover it subject to a fee.",
  plans_trial_faq_5_description_3:
    "If you have any difficulties or something is not clear for you, don't hesitate to get in touch with our technical support team.",
  plans_trial_faq_6_title:
    "How will I know when my usage period is about to expire?",
  plans_trial_faq_6_description_1:
    "When you log into your Calendesk account, the name of your service plan and the time remaining will be displayed in the right corner.",
  plans_trial_faq_6_description_2:
    "Shortly before the plan expires, you will also receive a pop-up notification right after you log in and we will also send you an email.",
  plan_selected_title: "Congratulations! You have selected the @{plan} plan.",
  plan_selected_subtitle_1:
    "Choose additional packages that will further enhance your capabilities!",
  plan_selected_subtitle_2:
    "You can enable or disable packages as you wish during the subscription.",
  "plugins_sms-notifications_cd_base_lite_name": "Contact Package",
  "plugins_sms-notifications_cd_base_lite_description":
    "Send SMS notifications to your customers simply and quickly. Confirm the booking, remind them about an appointment and accept date changes. The package contains 150 SMS messages with the possibility of buying new packages multiple times.",
  "plugins_sms-notifications_cd_base_standard_name": "Contact Pro Package",
  "plugins_sms-notifications_cd_base_standard_description":
    "Send even more SMS notifications to your customers. Remind them with special messages about promotions, special deals and birthday discounts. Encourage them to contact you and visit your site! The package contains 200 SMS messages with the possibility of buying new packages multiple times.",
  "plugins_sms-notifications_cd_base_pro_name": "Expert Contact Package",
  "plugins_sms-notifications_cd_base_pro_description":
    "Send even more SMS notifications to your customers. Remind them with special messages about promotions, special deals and birthday discounts. Encourage them to contact you and visit your site! The package contains 250 SMS messages with the possibility of buying new packages multiple times.",
  "plugins_mobile-app-staff_cd_base_lite_name": "Mobile app for employees",
  "plugins_mobile-app-staff_cd_base_lite_description":
    "Allow your employees to use a dedicated mobile app on their smartphones. Allow them to view the employee calendar, check the tasks assigned to them for the day and the customers they serve. Thanks to push notifications on the phone, employees will receive all the information they need right away.",
  plugins_booking_cd_base_lite_name: "Booking Package",
  plugins_booking_cd_base_lite_description:
    "Add to your application the ability to create a fully functional booking system for your customers. Offer them the opportunity to choose specific service options (e.g., wash, trim, brushing) and access the schedules of your employees – let them choose who they want to see.",
  "plugins_google-calendar_cd_base_standard_name": "Google Calendar",
  "plugins_google-calendar_cd_base_standard_description":
    "Keep track of events in real time, export all visits, orders and meetings to Google Calendar. Make them visible to your employees and customers. Make their lives as easy as possible and increase your business effectiveness. Create reminders, task lists and daily schedules – add to them Facebook events and remind your customers. Create quick meetings, meeting rooms and keep everything under control.",
  plugins_mobile_cd_base_lite_name: "Mobile Package",
  plugins_mobile_cd_base_lite_description:
    "Make your website fully adapted to the use of mobile devices (smartphones and tablets). With this package, you can easily achieve full compatibility.",
  "plugins_mobile-app-customers-pro_cd_base_standard_name":
    "Mobile Pro Package",
  "plugins_mobile-app-customers-pro_cd_base_standard_description":
    "Create a fully professional company application for mobile devices. Let your customers download on their phone an app with your logo and content that you decide yourself. Consider the design, specify the style, color and type of fonts. Choose the functionality and range of offered services – decide yourself from start to finish.",
  "plugins_mobile-app-customers-pro_cd_base_pro_name": "Mobile Expert Package",
  "plugins_mobile-app-customers-pro_cd_base_pro_description":
    "Create a fully professional company application for mobile devices. Let your customers download on their phone an app with your logo and content that you decide yourself. Consider the design, specify the style, color and type of fonts. Choose the functionality and range of offered services – decide yourself from start to finish.",
  plugins_management_cd_base_lite_name: "Management Package",
  plugins_management_cd_base_lite_description:
    "Unlock unrestricted access to the marketing knowledge base. Learn effective, simple and easy-to-use advice that will help you make better use of the opportunities of Calendesk and develop your business. Read articles, comments, studies and stay informed about important facts on a regular basis.",
  plugins_support_cd_base_standard_name: "Individual Expert Care Package",
  plugins_support_cd_base_standard_description:
    "Add to your application the possibility of receiving professional support. Your individual technical consultant will help you solve any problem, show you the best ways of doing things and help you avoid problems in the future.",
  "plugins_support-expert_cd_base_pro_name": "Individual Expert Care Package+",
  "plugins_support-expert_cd_base_pro_description":
    "Individual Priority Expert Care Package. Add to your application the possibility of receiving PREMIUM support. Your individual technical consultant will help you solve any problem as a priority. Your consultant will be available to you for an extended period of time and every matter will be resolved in an accelerated mode.",
  "plugins_easy-going_cd_base_standard_name": "Easy Going Package",
  "plugins_easy-going_cd_base_standard_description":
    "Serve an unlimited number of customers, unlock the full operational capabilities of Calendesk and let the application help you grow your business. And consistently increase your profits.",
  "plugins_alphanumeric-sms-sender_cd_base_standard_name":
    "Personalize the SMS sender name",
  "plugins_alphanumeric-sms-sender_cd_base_standard_description":
    "SMS sender name in all text messages to your customers. Replace boring and random phone numbers with your custom name. Let your customers know right away it's a message from you. You can set your name in the Profile -> Your company tab.",
  "plugins_alphanumeric-sms-sender_cd_base_pro_name":
    "Personalize the SMS sender name",
  "plugins_alphanumeric-sms-sender_cd_base_pro_description":
    "SMS sender name in all text messages to your customers. Replace boring and random phone numbers with your custom name. Let your customers know right away it's a message from you. You can set your name in the Profile -> Your company tab.",
  plans_thank_you_page_title:
    "Your plan and selected add-on packages have been activated!",
  plans_thank_you_page_title_part_1: "Your plan and selected add-on packages",
  plans_thank_you_page_title_part_2: "have been activated!",
  plans_thank_you_page_subtitle:
    "You can turn them on or off at any time in the tab “Your packages”.",
  plans_thank_you_page_button: "Get started with Calendesk",
  fill_invoice_data: "Please complete the billing details.",
  cancel_plan_confirm_button_1: "Ok I'm staying!",
  cancel_plan_confirm_button_2: "Cancel Subscription :(",
  purchase_remove: "Remove",
  wb_align_center: "Align center",
  wb_container_fluid: "Full section width",
  wb_image_to_right: "Picture to the right",
  wb_image_width: "The width of the picture in pixels, e.g. 200",
  wb_image_height: "The height of the picture in pixels, e.g. 200",
  wb_button_width: "The width of the button in pixels, e.g. 200",
  wb_image_contain: "Fit the entire picture",
  wb_button_elevation: "Shadow level e.g. 3",
  wb_height: "Section height",
  wb_image_hide_on_mobile: "Hide the picture on smartphones",
  wb_buttons_position_title: "Buttons position",
  wb_form_position_title: "Form position",
  wb_image_position_title: "Position of the pictures",
  wb_position_top_right_title: "Top right side",
  wb_position_top_left_title: "Top left side",
  wb_position_top_center_title: "Top middle",
  wb_position_bottom_right_title: "Bottom right side",
  wb_position_bottom_left_title: "Bottom left side",
  wb_position_bottom_center_title: "Bottom middle",
  wb_create_new_website: "Start from scratch",
  wb_image_hide: "Hide the picture",
  stripe_is_not_available_in_your_plan:
    "The Stripe payment package is not available on the Lite plan. Please upgrade to Standard or Pro.",
  fakturownia_is_not_available_in_your_plan:
    "The InvoiceOcean package is not available on the Lite plan. Please upgrade to Standard or Pro.",
  help: "Help",
  upselling: "Upsells",
  simple_store_product_list: "Products",
  simple_store_product_transaction_list: "Transactions",
  simple_store_product_list_product_name: "Name",
  simple_store_product_list_product_price: "Price",
  no_simple_store_product_info:
    "You don’t have any products yet, click below to add the first one.",
  no_subscriptions_info:
    "You don't have any subscriptions yet, click below to add the first one.",
  simple_store_product_add_title: "Add a product",
  subscriptions_add_title: "Add a subscription",
  subscriptions_update_title: "Update a subscription",
  no_simple_store_product_transaction_info:
    "You haven't registered the sale of any products yet. If products are already added, you can assign the sale of them to a selected client below.",
  simple_store_product_update_title: "Edit product",
  wb_button_edit_menu_simple_store_product: "Select product",
  wb_button_edit_menu_booking: "Select a service",
  wb_cta_button_type_1: "Redirect",
  wb_cta_button_type_2: "Booking",
  wb_cta_button_type_3: "Product",
  wb_cta_button_type_4: "Subscription",
  simple_store_product: "Product",
  booking_payments: "Bookings",
  payment_type_select_box: "Select the type of payment",
  wb_round_zero_title: "None",
  wb_round_sm_title: "Small",
  wb_round_md_title: "Medium",
  wb_round_lg_title: "Large",
  wb_round_xl_title: "XL",
  wb_round_pill_title: "Pill",
  wb_round_circle_title: "Circle",
  wb_image_rounded: "Picture rounding",
  wb_gallery_image_number: "Number of pictures in the gallery (desktop)",
  wb_gallery_mobile_image_number:
    "Number of pictures in the gallery (smartphone)",
  wb_gallery_width: "The width of the gallery in percentage, e.g. 50",
  wb_gallery_height: "The height of the gallery in pixels, e.g. 200",
  wb_gallery_distance: "Space between pictures",
  wb_image_zoom_on_click: "Enlarge the picture after clicking",
  wb_gallery_style: "Gallery style",
  wb_gallery_style_1_title: "Style 1",
  wb_gallery_style_2_title: "Style 2",
  wb_gallery_style_3_title: "Style 3",
  help_center_title:
    "Find out more about how Calendesk works by watching short videos.",
  help_center_group_1: "Introduction",
  help_center_group_2: "Booking system",
  help_center_group_3: "Customers and notifications",
  help_center_group_4: "Website builder",
  help_center_group_5: "Mobile application",
  help_center_group_6: "Payments",
  help_center_group_1_video_1_title:
    "Introduction to Calendesk – a quick overview of all the functions",
  help_center_group_1_video_2_title: "Complete your company details",
  help_center_group_2_video_1_title:
    "Employees – how do I add a new person to accept bookings?",
  help_center_group_2_video_2_title:
    "Services – what are they and how do I add a new service to my booking system?",
  help_center_group_2_video_3_title:
    "Services – how do I manage the list of services?",
  help_center_group_2_video_4_title:
    "Calendar – how do I manage my booking calendar?",
  help_center_group_2_video_5_title:
    "Calendar – events not related to a customer’s visit",
  help_center_group_2_video_6_title:
    "Calendar – limits of the customer calendar",
  help_center_group_3_video_1_title:
    "How do I manage customers in Calendesk? Send notifications to customers and newsletter.",
  help_center_group_3_video_2_title:
    "Personalize your email, SMS, and push notifications",
  help_center_group_4_video_1_title:
    "Your own website – an introduction to the Calendesk Website Builder",
  help_center_group_4_video_2_title: "Your own website – basic functionalities",
  help_center_group_4_video_3_title: "Your own website – header and footer",
  help_center_group_4_video_4_title:
    "Your own website – replacing the header and footer",
  help_center_group_4_video_5_title: "Your own website – how to add sections?",
  help_center_group_4_video_6_title: "Your own website – how to set a domain?",
  help_center_group_5_video_1_title:
    "Mobile application with the booking system – builder overview",
  help_center_group_5_video_2_title:
    "Mobile application with the booking system – presentation of the application in App Store or Google Play",
  help_center_group_6_video_1_title:
    "How to combine Stripe payments with the booking system and the store?",
  help_center_group_6_video_2_title:
    "How to enable additional payment operators in Stripe?",
  help_center_group_6_video_3_title:
    "How to charge fees online for my services?",
  help_center_group_6_video_4_title:
    "A simple online store in Calendesk – how do I charge for products online?",
  help_center_group_1_video_1_description:
    "In this video, you will learn about the possibilities of Calendesk. Here we will present the structure of the entire platform. With this guide, you will learn how to navigate the various Calendesk modules.",
  help_center_group_1_video_2_description:
    "Your company information may appear on your website and your mobile app. In this section, you will learn how to fill in your company data, where to set the opening hours of your workplace, where to add links to social media, and how to generate the Terms and Conditions and the Privacy Policy.",
  help_center_group_2_video_1_description:
    "In this video you will learn how to set up a booking system. Here we will tell you more about adding employees, granting them permissions and setting up their accounts in Calendesk.",
  help_center_group_2_video_2_description:
    "In this section of the guide, we will tell you how to add and configure services. Here you will learn how to add duration to services, assign prices to them, and how to describe them.",
  help_center_group_2_video_3_description:
    "The list of services is a place from which you can easily manage your services and add new ones.",
  help_center_group_2_video_4_description:
    "Find out how to use the Calendesk calendar here. In this video, we explain how to filter bookings by selecting an employee and how to manage bookings.",
  help_center_group_2_video_5_description:
    "What to do when an employee goes on vacation? This video will show you how to use the “Event unrelated to the customer’s visit” check box.",
  help_center_group_2_video_6_description:
    "Administrator privileges give you unlimited options for managing your calendar. Find out more from this video.",
  help_center_group_3_video_1_description:
    "In this section, you will find out what tools will help you to maintain a good customer relationship and how to manage customers in the Calendesk platform.",
  help_center_group_3_video_2_description:
    "Learn how to use notifications and how to modify them. It is a great tool for communicating with customers and employees!",
  help_center_group_4_video_1_description:
    "Check out how to navigate with our website builder to create a fully personalized website where your customers will book visits with you.",
  help_center_group_4_video_2_description:
    "See how your website is personalized. Building an image on the Internet can be much more fun than you thought!",
  help_center_group_4_video_3_description:
    "Learn more about the most important sections from which you will build your website.",
  help_center_group_4_video_4_description:
    "Learn how to replace the header and footer in the website builder.",
  help_center_group_4_video_5_description:
    "Sections in the website builder. Learn how to add and manage sections.",
  help_center_group_4_video_6_description:
    "In this video, we describe the process of adding your own domain. A good domain is a key to your business success. See how to set your network address.",
  help_center_group_5_video_1_description:
    "A mobile application with a booking system is something that everyone will envy. See how to create your own application and put it on the App Store and Google Play.",
  help_center_group_5_video_2_description:
    "See what your mobile app looks like in the store. In this video, you will learn how to do this.",
  help_center_group_6_video_1_description:
    "A booking system with payments is something a modern business needs. See how to connect your Stripe account to your Calendesk account.",
  help_center_group_6_video_2_description:
    "Stripe payments offer several additional configuration options. See how to include additional payments by third party payment providers that Stripe works with.",
  help_center_group_6_video_3_description:
    "See how to charge fees for your services at Calendesk. Here we will tell you about prepayments for the visit, payment statuses and you will see where to look for payment transactions.",
  help_center_group_6_video_4_description:
    "A simple online shop at Calendesk? It’s possible! See how to connect your products for which you charge one-time payments with your website.",
  related_articles: "Related articles",
  article_1_title: "Calendesk Platform – an overview",
  article_2_title: "Profile - your company",
  article_3_title: "Employees - adding an employee",
  article_4_title: "Services - add a category",
  article_5_title: "Services - add a service",
  article_6_title: "Services - list of services",
  article_7_title: "Employees - calendar",
  article_8_title: "Customers - list of customers",
  article_9_title: "Customers - add a customer",
  article_10_title: "Customers - bookings",
  article_11_title: "Customers - newsletter",
  article_12_title: "Customers - notifications",
  article_13_title: "Settings – notifications",
  article_14_title: "Add-ons – website",
  article_15_title: "Website – personalization",
  article_16_title: "Website – domain",
};
