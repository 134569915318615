export default {
  services_number_title: "Liczba usług do wyboru",
  additional_information_after_booking_title:
    "Dodatkowe informacje we wiadomości e-mail po rezerwacji",
  additional_information_after_booking_subtitle:
    "Dodatkowe informacje, które będą przesyłane w e-mailach związanych z rezerwacją usługi (utworzenie, zmiana oraz przypomnienie o rezerwacji). Jeśli edytowałeś powiadomienia, upewnij się, że w ich treści została ustawiona odpowiednia zmienna: [ADDITIONAL_INFORMATION_AFTER_BOOKING].",
  additional_information_after_booking_dummy_text:
    "To jest przykładowy tekst, który zostanie zastąpiony dodatkowymi informacjami po rezerwacji, jeśli zostały one podane w ustawieniach usługi.",
  plugins_storage_100gb_cd_base_pro_name: "Miejsce na pliki 100 GB",
  plugins_storage_500gb_cd_base_pro_name: "Miejsce na pliki 500 GB",
  plugins_storage_100gb_cd_base_pro_description:
    "Więcej miejsca na pliki. Zyskaj 100 GB dodatkowej przestrzeni na wymianę plików z klientami oraz przyjmowanie załączników przez formularze rezerwacji. Idealne rozwiązanie, które usprawni komunikację i ułatwi obsługę klientów.",
  plugins_storage_500gb_cd_base_pro_description:
    "Maksymalna przestrzeń na Twoje pliki. Skorzystaj z 500 GB przestrzeni, aby bez ograniczeń wymieniać pliki z klientami i odbierać załączniki przez formularze rezerwacji. Niezbędne dla dynamicznie rozwijających się firm, które cenią profesjonalizm i wygodę obsługi.",
  bookings_this_month: "Rezerwacje w tym miesiącu",
  bookings_all_processed: "Wszystkie rezerwacje",
  simple_store_product_transactions_this_month:
    "Sprzedane produkty w tym miesiącu",
  files_storage_usage: "Wykorzystane miejsce na pliki",
  files_storage_usage_from_available_prefix: "z dostępnych",
  not_available_in_standard_plan: "Funkcja niedostępna w planie Standard",
};
