import { NavigationGuardNext, Route } from 'vue-router'
import { closeApp, haveRequiredParams, haveStartParams } from './guardHelpers'
import store from '@/store'
import { errorNotification } from '@/lib/calendesk-js-library/tools/notification'
import { identifyUser, pushEvent } from '@/lib/calendesk-js-library/tools/helpers'
import { classToPlain } from 'class-transformer'
import DraftStateController, { DRAFT_VERSION } from '@/calendesk/controllers/DraftStateController'

function getConfigurationData (fetchDraft: boolean, to: any) {
  return new Promise((resolve, reject) => {
    const requests = [
      store.dispatch('setup/setReferrer', to.query.referrer),
      store.dispatch('auth/fetchUser'),
      store.dispatch('setup/fetchConfiguration'),
      store.dispatch('setup/fetchAdminConfiguration'),
      store.dispatch('setup/fetchCurrentTenantPlan')
    ]

    if (fetchDraft) {
      requests.push(store.dispatch('builder/fetchDraft'))
    }

    Promise.all(requests)
      .then(() => {
        let lang
        const draft = store.getters['builder/getDraft']

        if (fetchDraft && draft && draft.configuration.wb_primary_language__select__ &&
          draft.configuration.wb_primary_language__select__.value &&
          draft.configuration.wb_primary_language__select__.value) {
          lang = draft.configuration.wb_primary_language__select__.value
        } else {
          lang = store.getters['setup/getAppConfiguration'] ? store.getters['setup/getAppConfiguration'].language : 'en'
        }

        store.dispatch('setup/updateLanguage', lang, { root: true }).then(() => {
          store.dispatch('setup/setPreloader', false).then(() => {
            const user = store.getters['auth/getUser']
            const currentPlan = store.getters['setup/getCurrentTenantPlan']

            identifyUser(classToPlain(user), classToPlain(currentPlan))

            if (draft && draft.configuration.version !== DRAFT_VERSION) {
              draft.configuration.version = DRAFT_VERSION
              DraftStateController.getInstance().updateDraft(draft)
              DraftStateController.getInstance().updateSectionsIfNeeded(draft)
            }
            resolve(draft)
          })
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default (to: Route, from: Route, next: NavigationGuardNext): void => {
  if (to.name) {
    pushEvent(`goToPageWb_${to.name}`, {
      params: to.params
    })
  }

  const startBuilderPath = to.name === 'start' && to.query.tenant && to.query.session_id && to.params.draft_id
  const startCreatorPath = to.name === 'creator' && to.query.tenant && to.query.session_id

  if (!store.state.setup.tenant) {
    let tenant = null

    if (to.query.tenant) {
      // Restore tenant from the URL param
      tenant = to.query.tenant
    } else if (haveRequiredParams()) {
      // Restore tenant from local storage.
      tenant = localStorage.tenant
    }

    store.dispatch('setup/setTenant', tenant)
  }

  if (startBuilderPath || startCreatorPath) {
    store.dispatch('setup/init', to)
      .then(() => {
        getConfigurationData(!!startBuilderPath, to).then(() => {
          if (startBuilderPath) {
            next({ name: 'home', params: { locale: store.state.setup.configuration.language, internal: '1' }, replace: true })
          } else {
            next({ name: 'creator', params: { locale: store.state.setup.configuration.language }, replace: true })
          }
        }).catch((error) => {
          errorNotification('error_occurred', error)
          closeApp(error)
        })
      })
      .catch((error) => closeApp(error))
  } else {
    if (haveStartParams() || haveRequiredParams()) {
      if (!store.state.builder.draft || !store.state.setup.configuration || !store.state.setup.translations) {
        getConfigurationData(to.name !== 'creator', to).then(() => {
          const requestedLocale = to.params.locale

          if (requestedLocale !== store.state.setup.configuration.language) {
            next({ name: 'home', params: { locale: store.state.setup.configuration.language, internal: '1' }, replace: true })
          } else {
            next()
          }
        }).catch((error) => {
          errorNotification('error_occurred', error)
          closeApp(error)
        })
      } else {
        next()
      }
    } else {
      closeApp()
    }
  }
}
