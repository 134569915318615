export default {
  transaction_id: "ID de transacción",
  service_date: "Fecha del servicio",
  change_plan_button_title: "Cambiar el plan",
  trial_period: "Período de prueba",
  minimum_value_for_payment_error: "El valor mínimo es 2.",
  choose_tenant_dialog_title:
    "Seleccione la empresa en la que desea iniciar sesión",
  select: "Elegir",
  choose_tenant_dialog_forgotten_password_title:
    "Seleccione la empresa donde desea cambiar la contraseña",
  user_uses_email_already:
    "La dirección de correo electrónico seleccionada es utilizada por el cliente u otro empleado.",
  fields_required: "* - Campos requeridos",
  add_resources_with_plus:
    'No hay recursos. Añada recursos haciendo clic en el "+" de la derecha.',
  add_categories_with_plus:
    "Sin categorías. Agregue una categoría haciendo clic en el '+' a la derecha.",
  email_sent: "Correo electrónico enviado",
  sms_sent: "SMS enviado",
  payment_method_missing:
    'No tienes un método de pago conectado. Ve a la pestaña "Facturación" y agrega un método de pago.',
  invoice_data_missing:
    'No tienes datos de factura de IVA completos. Ve a la pestaña "Facturación" y completa los datos.',
  my_web_template: "Mi sitio web",
  no_websites_info:
    "Aún no has agregado ningún sitio web, haz clic abajo para agregar el primero.",
  no_website_info_button: "Agregar un sitio web",
  no_mobile_apps_info:
    "Aún no tienes un proyecto de aplicación móvil, haz clic abajo para agregar el primero.",
  no_mobile_apps_info_button: "Crea una aplicación móvil",
  my_mobile_app_template: "Mi aplicación móvil",
  company_name_2: "Razón social o nombre y apellido",
  go_back: "Deshacer",
  new_feature: "Nueva función en Calendesk",
  notifications_banner_text:
    "Personalice el correo electrónico, los SMS y las notificaciones automáticas con facilidad.",
  edit_now: "Editar ahora",
  notification_templates_list: "Plantillas",
  notification_templates_list_header: "Panel / Notificaciones",
  booking_prepayment: "Nueva reserva - pago requerido",
  booking_prepayment_info:
    'Esta notificación se envía si el servicio reservado requiere pago o si la reserva creada tiene el estado de "Prepago requerido". La notificación no se envía si la configuración de "Confirmación de reserva" está desactivada o si se seleccionó la opción "No informar de la creación de una visita" al crear la reserva.',
  booking_created: "Confirmación de reserva",
  booking_created_info:
    'Esta notificación se envía después de crear una reserva con el estado "Aceptado" y después de realizar el pago. No se envía una notificación si la configuración "Confirmación de reserva" está desactivada o si se seleccionó la opción "No informar de la creación de una visita" al crear la reserva.',
  booking_updated: "Cambio de reserva",
  booking_updated_info:
    'Esta notificación se envía después de cambiar una reserva (es decir, se ha realizado un cambio en el estado, fecha u hora de la reunión, empleado, cliente, ubicación o servicio). No se envía una notificación si la configuración "Cambio de reserva" está desactivada o si se seleccionó la opción "No informar sobre el cambio/eliminación de la visita" al cambiar la reserva.',
  booking_deleted: "Eliminación de reserva",
  booking_deleted_info:
    'Esta notificación se envía después de eliminar o cancelar una reserva. No se envía una notificación si el tipo de configuración "Eliminación de reserva" está desactivado o si se seleccionó la opción "No informar de la eliminación" al eliminar la reserva.',
  booking_reminder: "Recordatorio de reserva",
  booking_reminder_info:
    'La notificación se envía a las reservas con el estado "Aceptado" según el tiempo establecido antes de hacer la reserva. La notificación no se envía si el tipo de notificación "Recordatorio de reserva" está desactivado.',
  account_activate: "Activar cuenta - correo electrónico de bienvenida",
  account_activate_info:
    "Esta notificación se envía después de que un cliente haya creado su propia cuenta en la página generada por Calendesk.",
  "account_password-reset": "Restablecer contraseña",
  "account_password-reset_info":
    "Esta notificación se envía después de usar la opción de recordatorio de contraseña en la página generada por Calendesk.",
  newsletter_subscribe: "Suscripción al boletín de noticias",
  newsletter_subscribe_info:
    "Esta notificación se envía después de usar la opción de suscripción al boletín en la página generada por Calendesk.",
  "simple-store-product-transaction_paid":
    "Tienda - confirmación de compra del producto",
  edited: "Editado",
  "simple-store-product-transaction_paid_info":
    "La notificación se envía a la dirección de correo electrónico principal de la cuenta después de comprar productos en la página generada en Calendesk. La notificación no se envía al comprar productos durante la reserva de la visita (las notificaciones sobre reservas ya contienen esta información).",
  notification_info:
    "La edición de notificaciones lo ayudará a ajustar el contenido de las notificaciones a sus requisitos. Puede usar la edición avanzada de notificaciones, usando las llamadas variables, o dejar la configuración predeterminada. Las notificaciones predeterminadas contienen toda la información necesaria que deben recibir sus clientes y empleados.",
  notification_templates_list_for_client: "Notificaciones de clientes",
  notification_templates_list_for_tenant: "Notificaciones a su empleado",
  notification_templates_list_for_tenant_info:
    "Las notificaciones a los empleados se envían a las direcciones de correo electrónico de los empleados o, si faltan, a la dirección principal de la cuenta (pestaña Configuración / Tu empresa).",
  notification_templates_list_information:
    "En el menú a la izquierda, puedes personalizar tus notificaciones. Tienes la opción de usar variables en el contenido de los mensajes. Las variables tienen el formato “[NOMBRE]” y se reemplazarán automáticamente con valores correspondientes, como el nombre del servicio o el nombre del cliente. Estos valores se obtienen de la configuración de los servicios y las cuentas de los usuarios. Para seleccionar una variable, haz clic en el botón azul que simboliza un “+” (más) junto al título del mensaje, o el botón azul de “Haz clic aquí” ubicado debajo del campo donde configuras la notificación, y elige la variable que deseas incluir en el contenido.",
  ready: "Listo",
  notification_templates_list_preview_title:
    "Vista previa de mensajes de muestra",
  notification_template_editor_sidebar_email_title: "Correo electrónico",
  notification_template_editor_sidebar_sms_title: "SMS",
  notification_template_editor_sidebar_push_title: "Pulsar el teléfono",
  notification_template_editor_sidebar_text: "Texto",
  notification_template_editor_publish_title:
    "Cambiar el contenido de las notificaciones",
  notification_template_editor_publish_cancel_title: "Eliminar cambios",
  notification_template_editor_publish_text:
    "El cambio entrará en vigor inmediatamente.",
  notification_template_editor_publish_cancel_text:
    "Los cambios realizados serán eliminados.",
  notification_template_editor_publish_warning:
    "¡Esta operación no se puede deshacer!",
  notification_template_editor_publish_confirmation_button:
    "Cambiar notificaciones",
  notification_template_editor_publish_cancel_confirmation_button:
    "Eliminar cambios",
  notification_template_editor_sidebar_restore_all:
    "Restaurar contenido predeterminado",
  notification_template_editor_sidebar_restore_all_information:
    "Se restaurarán las notificaciones predeterminadas de este grupo.",
  notification_template_editor_sidebar_restore_all_button: "Restaurar",
  booking_prepayment_email_subject_client:
    "Pago requerido: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_prepayment_email_text_client:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p>Tu reserva para <b>[SERVICE_NAME]</b> requiere pago.</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Cantidad:</b><br>[BOOKING_PRICE]</p>[BOOKING_CUSTOM_FIELDS]<p><b>Tu reserva será cancelada si no se realiza el pago dentro de [BOOKING_AWAITING_PAYMENT_FULL_TIME]. Haz clic abajo para ir a tu reserva y realizar el pago.</b></p><p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON]',
  booking_created_email_subject_client:
    "Confirmado: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_created_email_text_client:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p>Tu reserva para <b>[SERVICE_NAME]</b> ha sido confirmada.</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Ubicación:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON][ADD_TO_CALENDAR_BUTTONS]',
  booking_created_email_subject_tenant:
    "Nueva reserva: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_created_email_text_tenant:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p><b>Se ha realizado una nueva reserva para el servicio:</b><br> [SERVICE_NAME]</p><p><b>Cliente:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Empleado:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Ubicación:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_updated_email_subject_client:
    "Cambio de reserva: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_updated_email_text_client:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p>Tu reserva para <b>[SERVICE_NAME]</b> ha sido actualizada.</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Ubicación:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON][ADD_TO_CALENDAR_BUTTONS]',
  booking_updated_email_subject_tenant:
    "Cambio de reserva: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_updated_email_text_tenant:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p><b>Se ha realizado un cambio en la reserva para el servicio:</b><br> [SERVICE_NAME]</p><p><b>Cliente:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Empleado:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Ubicación:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_deleted_email_subject_client:
    "Reserva cancelada: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_deleted_email_text_client:
    "<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p>Te informamos que la reserva para el servicio <b>[SERVICE_NAME]</b> ha sido cancelada.</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p>",
  booking_deleted_email_subject_tenant:
    "Reserva cancelada: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_deleted_email_text_tenant:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p>Te informamos que la reserva para el servicio <b>[SERVICE_NAME]</b> ha sido cancelada.</p><p><b>Cliente:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Empleado:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_reminder_email_subject_client:
    "Recordatorio de reserva: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_reminder_email_text_client:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p>Este es un recordatorio de tu reserva para el servicio <b>[SERVICE_NAME]</b></p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Ubicación:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON]',
  booking_reminder_email_subject_tenant:
    "Recordatorio de reserva: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_reminder_email_text_tenant:
    '<h1>Número de reserva: [BOOKING_ID]</h1><hr/><p>Hola,</p><p>Este es un recordatorio de la reserva para el servicio:</p><p> [SERVICE_NAME]</p><p><b>Cliente:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Empleado:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Fecha y hora:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Ubicación:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS] [ADDITIONAL_INFORMATION_AFTER_BOOKING]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_created_push_subject_client: "Gracias por reservar",
  booking_created_push_text_client:
    "[SERVICE_NAME] en [COMPANY_NAME] el [BOOKING_START_DATE] a las [BOOKING_START_TIME].",
  booking_created_push_subject_tenant: "[COMPANY_NAME] - Nueva reserva",
  booking_created_push_text_tenant:
    "Servicio: [SERVICE_NAME], Klient: [CUSTOMER_FULL_NAME], fecha: [BOOKING_START_DATE], hora: [BOOKING_START_TIME].",
  booking_updated_push_subject_client: "Tu reserva ha cambiado",
  booking_updated_push_text_client:
    "[SERVICE_NAME] con [COMPANY_NAME] con dniu [BOOKING_START_DATE] o godz. [BOOKING_START_TIME], estado: [BOOKING_STATUS].",
  booking_updated_push_subject_tenant: "[COMPANY_NAME] - Cambiar reserva",
  booking_updated_push_text_tenant:
    "Servicio: [SERVICE_NAME], Cliente: [CUSTOMER_FULL_NAME], fecha: [BOOKING_START_DATE], hora: [BOOKING_START_TIME], estado: [BOOKING_STATUS].",
  booking_deleted_push_subject_client: "La reserva ha sido eliminada.",
  booking_deleted_push_text_client:
    "[SERVICE_NAME] en [COMPANY_NAME] el [BOOKING_START_DATE] a las [BOOKING_START_TIME].",
  booking_deleted_push_subject_tenant: "[COMPANY_NAME]: La reserva se eliminó",
  booking_deleted_push_text_tenant:
    "Servicio: [SERVICE_NAME], Cliente: [CUSTOMER_FULL_NAME], fecha: [BOOKING_START_DATE], hora: [BOOKING_START_TIME].",
  booking_reminder_push_subject_client: "Recordatorio de reserva",
  booking_reminder_push_text_client:
    "[SERVICE_NAME] en [COMPANY_NAME] el [BOOKING_START_DATE] a las [BOOKING_START_TIME].",
  booking_reminder_push_subject_tenant:
    "[COMPANY_NAME] - Recordatorio de reserva",
  booking_reminder_push_text_tenant:
    "Servicio: [SERVICE_NAME], Cliente: [CUSTOMER_FULL_NAME], fecha: [BOOKING_START_DATE], hora: [BOOKING_START_TIME].",
  booking_created_sms_text_client:
    "Gracias por reservar [SERVICE_NAME] en [COMPANY_NAME] el [BOOKING_START_DATE] a las [BOOKING_START_TIME].",
  booking_created_sms_text_tenant:
    "[COMPANY_NAME] - Nueva reserva: [SERVICE_NAME]. Cliente: [CUSTOMER_FULL_NAME], fecha: [BOOKING_START_DATE], hora: [BOOKING_START_TIME].",
  booking_updated_sms_text_client:
    "Se modificó la reserva de [SERVICE_NAME] en [COMPANY_NAME]. El [BOOKING_START_DATE] a las [BOOKING_START_TIME], estado: [BOOKING_STATUS].",
  booking_updated_sms_text_tenant:
    "[COMPANY_NAME] - Cambio de reserva: [SERVICE_NAME]. Cliente: [CUSTOMER_FULL_NAME], fecha: [BOOKING_START_DATE], hora: [BOOKING_START_TIME], estado: [BOOKING_STATUS].",
  booking_deleted_sms_text_client:
    "Se eliminó la reserva de [SERVICE_NAME] en [COMPANY_NAME]. El [BOOKING_START_DATE] a las [BOOKING_START_TIME].",
  booking_deleted_sms_text_tenant:
    "[COMPANY_NAME] - Reservas eliminadas: [SERVICE_NAME]. Cliente: [CUSTOMER_FULL_NAME], Fecha: [BOOKING_START_DATE], Hora: [BOOKING_START_TIME].",
  booking_reminder_sms_text_client:
    "Le recordamos que visite [COMPANY_NAME] el [BOOKING_START_DATE] a las [BOOKING_START_TIME]. Tel.: [COMPANY_PHONE].",
  booking_reminder_sms_text_tenant:
    "[COMPANY_NAME]: Recordatorio sobre la reserva en [SERVICE_NAME]. Cliente: [CUSTOMER_FULL_NAME], Fecha: [BOOKING_START_DATE], Hora: [BOOKING_START_TIME].",
  account_activate_email_subject_client:
    "Confirmación de registro en el sitio web de [COMPANY_NAME]",
  account_activate_email_text_client:
    "<h1>Bienvenido a [COMPANY_NAME]</h1><p>Nos alegra que quieras unirte a nosotros. Estás a solo un paso de utilizar las herramientas que facilitan el uso de nuestros servicios. Completa tu registro activando tu cuenta.</p>",
  account_activate_email_cta_button_client: "Activar cuenta",
  "account_password-reset_email_subject_client":
    "Tu contraseña para [COMPANY_NAME]",
  "account_password-reset_email_text_client":
    "<h1>Tu contraseña para [COMPANY_NAME]</h1><p>Haz clic abajo para establecer tu contraseña de cuenta en [COMPANY_NAME].</p>",
  "account_password-reset_email_cta_button_client": "Establecer una contraseña",
  newsletter_subscribe_email_subject_client:
    "Confirmación de suscripción al boletín en [COMPANY_NAME]",
  newsletter_subscribe_email_text_client:
    '<h1>Confirmación de suscripción al boletín de [COMPANY_NAME]</h1><p class="cd-paragraph">Haz clic abajo para confirmar tu suscripción al boletín de [COMPANY_NAME]. Si has recibido este mensaje por error, simplemente puedes ignorarlo.</p>',
  newsletter_subscribe_email_cta_button_client: "Confirmar",
  "simple-store-product-transaction_paid_email_subject_client":
    "Gracias por su comprar [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_client":
    '<h1>Este es un comprobante de pago</h1><p class="cd-paragraph">Gracias por comprar [PRODUCT_NAME].</p><p>Precio: [PRODUCT_PRICE], Cantidad: [PRODUCT_QUANTITY].</p><p>Información adicional: [PRODUCT_DESCRIPTION]</p>',
  "simple-store-product-transaction_paid_email_subject_tenant":
    "Nueva compra [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_tenant":
    '<h1>Nueva compra de [PRODUCT_NAME]</h1><p class="cd-paragraph">Tu producto [PRODUCT_NAME] ha sido comprado por el cliente [CUSTOMER_FULL_NAME].</p><p>Precio: [PRODUCT_PRICE], Cantidad: [PRODUCT_QUANTITY].</p><p>Información adicional: [PRODUCT_DESCRIPTION]</p>',
  employee_example_name: "Ene",
  employee_example_surname: "Ocupado",
  employee_example_fullname: "Juan Nadie",
  customer_example_name: "Anna",
  customer_example_surname: "Nadie",
  customer_example_fullname: "Carmen Fernandez",
  service_example_name: "Servicio ABC",
  service_example_name_2: "Consulta en línea",
  phone_number_is_missing: "Sin número de teléfono",
  company_address_is_missing: "Sin dirección de empresa",
  sms_title: "Mensaje",
  email_subject: "Tema",
  push_now_indicator: "Ahora",
  variable_customer_full_name: "Nombre y apellido del cliente",
  variable_customer_id: "ID cliente",
  variable_customer_name: "Nombre del cliente",
  variable_customer_surname: "Nombre del cliente",
  variable_customer_email: "Correo electrónico del cliente",
  variable_customer_phone: "Telefono del cliente",
  variable_employee_id: "Número de identificación del empleado",
  variable_employee_name: "Nombre del empleado",
  variable_employee_email: "Correo electrónico del empleado",
  variable_employee_phone: "Teléfono del empleado",
  variable_employee_surname: "Nombre del empleado",
  variable_employee_full_name: "Nombre y apellidos del empleado",
  variable_booking_id: "Número de reserva",
  variable_booking_start_date: "Fecha de reservada",
  variable_booking_start_time: "Hora de la reserva",
  variable_booking_end_date: "Fecha de finalización de la reserva",
  variable_booking_end_time: "Hora de finalización de la reserva",
  variable_service_id: "Número de identificación del servicio",
  variable_service_name: "Nombre del servicio",
  variable_booking_price: "Precio de servicio",
  variable_company_name: "Nombre de empresa",
  variable_company_phone: "Teléfono de empresa",
  variable_company_address: "Dirección de empresa",
  variable_booking_status: "Estado de la reserva",
  variable_booking_payment_status: "Estado del pago de la reserva",
  variable_newsletter_email: "Dirección de correo electrónico",
  variable_unsubscribe_url: "Escribe la url",
  variable_product_id: "Número de identificación del producto",
  variable_product_name: "Nombre del producto",
  variable_product_description: "Información adicional del producto",
  variable_product_price: "Precio del producto",
  variable_product_quantity: "Número de artículos del producto.",
  variable_product_list: "Lista de productos comprados",
  variable_booking_location: "Ubicación",
  variable_booking_time_zone: "Zona horaria",
  variable_booking_recurrence_description: "Descripción de la reserva cíclica",
  variable_online_meeting_url: "Enlace de reunión en línea",
  variable_booking_control_url: "URL de reserva",
  variable_booking_created_by_user_id: "Número de identificación del creador",
  variable_booking_updated_by_user_id:
    "Número de identificación del último editor",
  variable_booking_created_by: "Nombre del creador",
  variable_booking_updated_by: "Nombre del último editor",
  variable_booking_custom_fields: "Campos del formulario de reserva",
  add_variable_info_text: "Haga clic aquí para agregar más opciones",
  rename: "Renombrar",
  hide: "Ocultar",
  change_url: "Cambiar enlace",
  back: "Atrás",
  your_pictures: "Tu galeria",
  photo_library_unsplash: "Fototeca (gratis)",
  search_for_photos: "Busca entre millones de fotos",
  search_for_photos_hint: "Unsplash solo te permite buscar en inglés.",
  photos_by_unsplash: "Imágenes de Unsplash",
  photo_by_on_unsplash: "Autor de la foto @{name} de Unsplash",
  unsplash_photo_added_to_gallery: "La foto ya está disponible en su galería.",
  for_example_unsplash: "Por ejemplo, las flores",
  new_website_builder_title: "Nuevo editor de sitio web",
  website_builder_banner_text:
    "¡Esta aquí! ¡Crea tu sitio web en el nuevo editor!",
  check_now: "Revisar ahora",
  // Color picker
  cp_alert_p1: "¡Elegiste un contraste demasiado bajo!",
  cp_alert_p2: "El elemento será difícil de ver.",
  // Website builder
  wb_website_builder: "Creador del sitio web",
  wb_move_up: "Subir",
  wb_move_down: "Bajar",
  wb_auto_save_info: "Los cambios se guardan automáticamente",
  wb_pages: "Subpáginas",
  wb_add_new_section: "Añadir una sección",
  wb_select_new_section: "Seleccione una sección",
  wb_add_new_section_info: "Seleccione una sección del menú de la izquierda",
  wb_add_new_page: "Agregar una subpágina",
  wb_add_page_dialog_type_page_name_label: "Nombre, por ejemplo, Servicios",
  wb_add_page_dialog_type_page_name_blog_label: "Nombre, por ejemplo, Blog",
  wb_add_page_dialog_type_page_description:
    "Descripción (visible para motores de búsqueda como Google)",
  wb_add_page_dialog_type_page_description_hint:
    "Ingrese una descripción única y breve del contenido de su página. Esta descripción será visible en los resultados de motores de búsqueda, como Google, bajo el título de su página.",
  wb_add_page_dialog_type_page_path_label:
    "Dirección por dominio, por ejemplo, servicio",
  wb_add_page_dialog_type_page_path_blog_label:
    "Dirección después del dominio, por ejemplo, blog",
  wb_add_page_dialog_type_main_page: "Página de inicio",
  wb_add_page_dialog_type_page: "Subpágina",
  wb_add_page_dialog_type_redirect: "Redirigir (enlace externo)",
  wb_add_page_dialog_type_blog_handy: "Blog",
  wb_add_page_dialog_type_redirect_url: "Redirigir la dirección www",
  wb_add_page_dialog_type_blog_handy_id:
    "ID de BlogHandy, por ejemplo, 609wl61ZYL0ArTAFR23",
  wb_blog_handy_description:
    "BlogHandy es una plataforma externa que te permite dirigir fácilmente un blog. BlogHandy puede integrarse fácilmente con la página www en Calendesk.",
  wb_blog_handy_info_1:
    "Para agregar un blog a tu página de Calendesk, sigue las instrucciones a continuación:",
  wb_blog_handy_info_2:
    "Haz clic a continuación y regístrate en la plataforma de BlogHandy.",
  wb_blog_handy_info_3:
    "Copia el identificador de Blog de tu cuenta de BlogHandy y pégalo en el campo de abajo.",
  wb_blog_handy_info_4:
    "¡Felicidades! 🎉 Cada publicación de blog publicada en BlogHandy aparecerá automáticamente en tu página de Calendesk.",
  wb_blog_handy_signup_button_title: "Regístrate en BlogHandy",
  wb_general: "General",
  wb_style: "Estilo",
  wb_section: "Sección de página",
  wb_color_primary: "Botones y esenciales",
  wb_color_bg: "Primer fondo",
  wb_color_text: "Texto en primer plano",
  wb_color_bg_2: "Segundo fondo",
  wb_color_text_2: "Texto sobre el segundo fondo",
  wb_google_tag_id: "Google Tag ID",
  wb_website_description: "Descripción del sitio web para robots (SEO)",
  wb_title: "Bóveda",
  wb_text: "Contenido",
  wb_subtitle: "Subtitular",
  wb_description: "Descripción",
  wb_image: "Gráficos",
  wb_image_1: "Gráficos 1",
  wb_image_2: "Gráficos 2",
  wb_image_3: "Gráficos 3",
  wb_image_4: "Gráficos 4",
  wb_background: "Fondo",
  wb_category_section_navbar_1: "Encabezado con logo",
  wb_category_section_navbar_2: "Encabezado sin logo",
  wb_category_section_footer: "Pie de página",
  wb_category_section_employees: "Empleados",
  wb_category_section_services: "Servicios",
  wb_category_section_booking: "Reservar una visita",
  wb_category_section_about_us: "Sobre nosotros",
  wb_category_section_feature: "Universal",
  wb_category_section_review: "Opiniones",
  wb_category_section_other: "Otro",
  wb_category_section_header: "Bóveda",
  wb_category_section_newsletter: "Boletín informativo",
  wb_category_section_form: "Formulario de contacto",
  wb_category_section_cta: "Llamada a la acción (CTA)",
  wb_decrease_section: "Disminuir el zoom",
  wb_increase_section: "Ampliar",
  wb_company_name: "Nombre de empresa",
  wb_login_modal: "Registrar",
  wb_login_subtitle: "Subtitular",
  wb_sign_up: "Crea una cuenta",
  wb_login: "Iniciar sesión",
  wb_sign_up_modal: "Registro",
  wb_menu_bookings: "Reservas",
  wb_menu_payments: "Pago",
  wb_menu_settings: "Ajustes",
  wb_menu_logout: "Cerrar sesión",
  wb_forgotten_password_modal: "Recordatorio de contraseña",
  wb_forgotten_password_confirmation_modal:
    "Confirmación de que el correo electrónico ha sido enviado",
  wb_forgotten_password_title: "Título",
  wb_forgotten_password_subtitle: "Subtitular",
  wb_submit_button: "Botón confirmar",
  wb_forgotten_password_modal_confirmation_title: "Título",
  wb_forgotten_password_modal_confirmation_subtitle: "Subtitular",
  wb_company_address: "Dirección",
  wb_company_email: "Dirección de correo electrónico",
  wb_company_phone: "Teléfono",
  wb_menu_title: "Título del menú",
  wb_account_title: "Perfil",
  wb_signup: "Registro",
  wb_profile: "Reservas",
  wb_settings: "Ajustes",
  wb_company_slogan: "Eslogan",
  wb_service_label: "Etiqueta de servicio",
  wb_employee_label: "Etiqueta del empleado",
  wb_employee_info_label: "Texto sobre el campo de los empleados",
  wb_booking_button: "Botón de reserva",
  wb_page_list: "Editando el menú",
  wb_menu_column_1: "Menú 1",
  wb_menu_column_2: "Menú 2",
  wb_publish_confirm_dialog_title: "¿Publicar la página?",
  wb_publish_confirm_dialog_text:
    "El sitio web estará visible en Internet en un minuto.",
  wb_publish_success_text:
    "¡Felicidades! Su sitio web estará disponible en Internet en un momento.",
  wb_more_pages_button: "Otras páginas",
  wb_sign_up_terms_info:
    "¿Mostrar información sobre la aceptación de las normas?",
  wb_select_data_label: "Por favor seleccione una fecha",
  wb_edit_page: "Editar la página",
  wb_remove_page_confirm_dialog_title: "¿Eliminar página?",
  wb_remove_page_confirm_dialog_text: "La página se eliminara",
  wb_remove_page_success_text: "La página ha sido eliminada.",
  wb_remove_confirm_dialog_title: "¿Está seguro?",
  wb_remove_confirm_dialog_text: "¡Este cambio no se puede deshacer!",
  wb_remove_success_text: "Eliminación completada con éxito",
  wb_employees_title: "Nuestro equipo",
  wb_employees_no_data:
    "Agregue empleados en el panel de Calendesk para ver una vista previa.",
  wb_services_no_data:
    "Agregue servicios en el panel de Calendesk para ver una vista previa.",
  wb_employee_list: "Especialistas",
  wb_service_list: "Servicios",
  wb_items_selector_info: "Haga clic a continuación para agregar elementos",
  wb_change: "Sustituir",
  wb_replace_navbar: "Sustitur el encabezado",
  wb_replace_footer: "Sustituir pie de página",
  wb_main_pages_number: "Número de pestañas principales",
  wb_button: "Botón",
  wb_domain_example: "Por ejemplo, miempresa.es",
  wb_no_domain_info: "No tiene un dominio configurado",
  wb_book_online: "Botón de reserva",
  wb_button_title: "Botón",
  wb_select_subpage: "Subpágina",
  wb_features: "Características",
  wb_add_button: "Agregar un botón",
  wb_new_button: "Nuevo botón",
  wb_button_edit_menu_redirect: "Seleccione una redirección",
  wb_button_edit_menu_style: "Establecer el estilo y el tamaño",
  wb_button_outlined: "Reseña",
  wb_button_rounded: "Redondeado",
  wb_button_size: "Tamaño",
  wb_button_dark: "Oscuro",
  wb_redirect: "Reenvío",
  wb_add_sections_feature: "Añadir artículo",
  wb_dummy_text_about_us: "Entonces como",
  wb_dummy_text_service: "Servicio",
  wb_dummy_text_select_service: "Elige un servicio",
  wb_dummy_text_select_employee: "Elige una persona",
  wb_dummy_selected_services: "Servicios seleccionados",
  wb_dummy_text_select_date: "Por favor seleccione una fecha",
  wb_dummy_text_select_date_and_hour: "Elige una fecha y hora",
  wb_dummy_text_services: "Nuestros servicios",
  wb_dummy_text_book_now: "Reservar",
  wb_dummy_text_employees: "Nuestro equipo",
  wb_dummy_text_more: "Ver más",
  wb_dummy_text_buy_now: "Comprar ahora",
  wb_dummy_text_regulations: "Normativa",
  wb_dummy_text_privacy_policy: "Política de privacidad",
  wb_dummy_text_reviews_title: "Opiniones de nuestros clientes",
  wb_dummy_text_reviews_full_name: "Jan kowalski",
  wb_dummy_text_review_example:
    "Este lugar es un verdadero acierto, he estado usando sus servicios durante mucho tiempo y todavía me sorprenden. Definitivamente recomiendo :)",
  wb_dummy_text_booking_title:
    "Reserve tus visitas en nuestra web  en minutos sin salir de  casa y completamente en línea.",
  wb_dummy_text_1:
    "Un lugar moderno ubicado en el centro de Varsovia. ¡Siempre ahí para ti! Sólo los mejores de los mejores trabajan con nosotros. No nos olvidamos de capacitarnos y adquirir conocimientos que luego podemos compartir contigo. Ponte en nuestras manos. Utilizamos soluciones modernas. Puedes pedir cita completamente online, tienes a tu disposición una moderna aplicación móvil, donde recibirás la información más importante de nuestra parte.",
  wb_dummy_text_2:
    "Infórmese de las posibilidades que le ofrecen nuestros servicios",
  wb_dummy_text_3: "¿Que ofrecemos?",
  wb_dummy_text_4: "¿De qué te gustaría hablar?",
  wb_dummy_text_5: "Por favor ingrese su nombre completo a continuación",
  wb_dummy_text_6:
    "Este es un ejemplo de una descripción de servicio. Lo configurará en el panel de administración de Calendesk cuando edite los servicios. Puede elegir cualquier texto para diferentes servicios.",
  wb_dummy_text_title: "Texto de ejemplo",
  wb_dummy_subject_title: "Mensaje del formulario en el sitio web",
  wb_dummy_form_sent_success_title:
    "Gracias, el formulario fue enviado correctamente.",
  wb_dummy_form_sent_success_description:
    "Nos pondremos en contacto con usted tan pronto como sea posible.",
  wb_dummy_text_newsletter_1:
    "Información sobre nuevos servicios y productos directo a tu e-mail",
  wb_dummy_text_newsletter_2: "Suscríbete a nuestro boletín y mantente al día",
  wb_dummy_text_newsletter_3: "Suscribir",
  wb_dummy_text_newsletter_4: "Dirección de correo electrónico",
  wb_dummy_text_newsletter_5:
    "¡Gracias! Hemos enviado un enlace de activación a su dirección de correo electrónico.",
  wb_dummy_text_newsletter_6: "Más de lo que piensas",
  wb_regulations_info:
    "Puedes editar el contenido de la normativa en el panel de Calendesk en la pestaña Configuración/Tu empresa.",
  wb_privacy_policy_info:
    "Puede editar el contenido de la política de privacidad en el panel de Calendesk en la pestaña Configuración / Su empresa.",
  wb_regulations_page: "Página de reglas",
  wb_privacy_policy_page: "Página de política de privacidad",
  wb_login_enabled: "Inicio de sesión habilitado",
  wb_booking_confirmation: "Confirmación de reserva",
  wb_booking_created_summary: "Confirmación de que el mensaje fue enviado",
  wb_booking_created_payment_summary: "Información sobre el pago requerido",
  wb_creator_chat_1:
    "Hola &#128075;. Soy tu asistente virtual para la creación de sitios web. Te ayudaré a configurar tu sitio web en minutos.",
  wb_creator_chat_2: "Elige el tipo de tu negocio",
  wb_creator_chat_3:
    "¡Gracias! El primer paso está en nosotros &#128588;. Es hora de elegir los elementos que aparecerán en tu nuevo sitio web.",
  wb_creator_chat_4:
    "Elige el estilo que aplicaremos en la web. Los colores se pueden cambiar libremente.",
  wb_creator_chat_5:
    "¡Esta casi terminado! Puede tener varios bocetos de página y cambiarlos según sea necesario. ¿Cómo quieres que se llame el boceto actual?",
  wb_creator_chat_6:
    "Listo &#128079;! Lo hiciste genial. Estoy preparando la pagina, dame un momento... &#128640;&#128640;",
  wb_creator_chat_6a: "Será genial &#129321;",
  wb_creator_chat_6b: "Casi termino ...",
  wb_creator_chat_6c: "Solo unas pocas líneas más de código y listo...",
  wb_select_draft_name:
    "¿Cómo quieres que se llame el borrador de la página actual?",
  wb_draft_name_text_field_label: "Nombre de la pagina",
  wb_chat_with_us: "Chatea con soporte técnico",
  wb_selected_n_items: "Artículos seleccionados: @{amount}.",
  wb_business_1: "Salón de belleza",
  wb_business_2: "Salón spa",
  wb_business_3: "Salón de belleza",
  wb_business_4: "Nutricionista",
  wb_business_5: "Lecciones privadas",
  wb_business_6: "Cuidado de niños",
  wb_business_7: "Eventos empresariales",
  wb_business_8: "Ayuntamiento",
  wb_business_9: "Servicios financieros",
  wb_business_10: "Sala de escape",
  wb_business_11: "Servicios jurídicos",
  wb_business_12: "Medicamento",
  wb_business_13: "Autoescuela",
  wb_business_14: "Fotógrafo",
  wb_business_15: "Psicólogo",
  wb_business_16: "Servicios de consultoría",
  wb_business_17: "Entrenamiento",
  wb_business_18: "Cuidado de animales",
  wb_business_19: "Diseño de interiores",
  wb_business_20: "Servicios de limpieza",
  wb_business_21: "Deporte",
  wb_business_22: "Entrenador personal",
  wb_business_23: "Gimnasio",
  wb_business_24: "Aptitud física",
  wb_business_25: "Yoga",
  wb_business_26: "Club de Tenis",
  wb_business_27: "Salas de reuniones",
  wb_business_28: "Restaurante",
  wb_business_29: "Fisioterapia",
  wb_business_30: "Otro",
  wb_website_element_1: "Inicio de sesión y registro del cliente",
  wb_website_element_2: "Reservas en línea",
  wb_website_element_3: "Servicios",
  wb_website_element_4: "Entonces como",
  wb_website_element_5: "Equipo",
  wb_website_element_6: "Normativa y política de privacidad",
  wb_website_element_7: "Galería",
  wb_website_element_8: "Boletín informativo",
  wb_website_element_tooltip_1:
    "Le permitiremos configurar una cuenta e iniciar sesión para sus clientes.",
  wb_website_element_tooltip_2:
    "Añadiremos un apartado con la posibilidad de realizar reservas online.",
  wb_website_element_tooltip_3:
    "Agregaremos una sección y una subpágina con sus servicios.",
  wb_website_element_tooltip_4:
    "Agregaremos una sección con información sobre su empresa.",
  wb_website_element_tooltip_5:
    "Agregaremos una sección y una subpágina con sus empleados.",
  wb_website_element_tooltip_6:
    "Añadiremos una página con normativa y política de privacidad. Puede leer todo desde el panel de Calendesk.",
  wb_website_element_tooltip_7:
    "Agregaremos una sección con una galería de fotos.",
  wb_website_element_tooltip_8: "Añadiremos una sección de newsletter.",
  wb_website_style_1: "Tormenta de mar",
  wb_website_style_2: "Acero del mar",
  wb_website_style_3: "Polvo pastel",
  wb_website_style_4: "Amapola brumosa",
  wb_website_style_5: "Mundo forestal",
  wb_website_style_6: "Crepúsculo de miel",
  wb_website_style_7: "Radiante sonrisa",
  wb_website_style_8: "Un beso rojo",
  wb_website_style_9: "Verde saludable",
  wb_website_style_10: "Nube de lluvia",
  wb_website_style_11: "Bayas en Chocolate",
  wb_website_style_12: "Polvo naranja",
  wb_draft_name_example: "Mi página - @{template}",
  wb_default_login_text: "Debe iniciar sesión para poder reservar una cita.",
  wb_default_forgotten_password_title:
    "Todo el mundo olvida su contraseña a veces",
  wb_default_forgotten_password_subtitle:
    "Ingrese la dirección de correo electrónico que utilizó al registrarse para enviarle las instrucciones de cambio de contraseña.",
  wb_default_forgotten_password_modal_confirmation_title: "Revise tu correo",
  wb_default_forgotten_password_modal_confirmation_subtitle:
    "Si la dirección de correo electrónico existe en el sistema, le acabamos de enviar las instrucciones para cambiar la contraseña.",
  wb_default_booking_created_summary:
    "Hemos enviado una confirmación de reserva a su dirección de correo electrónico.",
  wb_default_address: "Madrid, Calle Gran Via 1, 28-001",
  wb_default_email: "jan.kowalski@calendsk.com",
  wb_default_product_name: "Producto de muestra",
  wb_default_product_description: "Esta es información adicional del producto.",
  wb_default_phone: "+48 22 200 20 20",
  wb_default_menu_title: "Menú",
  wb_default_profile_title: "Perfil",
  wb_main_page_name: "Hogar",
  wb_main_page_url: "hogar",
  wb_employees_page_name: "Entonces como",
  wb_team_page_name: "Nuestro equipo",
  wb_employees_page_url: "En lo",
  wb_team_page_url: "nuestro-equipo",
  wb_services_page_name: "Servicios",
  wb_services_page_name_2: "Oferta",
  wb_services_page_url: "Servicios",
  wb_services_page_url_2: "Oferta",
  wb_regulations_page_name: "Normativa",
  wb_regulations_page_url: "Normativa",
  wb_privacy_policy_page_name: "Política de privacidad",
  wb_privacy_policy_page_url: "Política de privacidad",
  wb_dark_mode: "Modo oscuro",
  wb_facebook: "Dirección URL - Facebook",
  wb_instagram: "Dirección URL - Instagram",
  wb_twitter: "Dirección URL - Twitter",
  wb_youtube: "Dirección URL: YouTube",
  wb_section_missing_question:
    "¿Falta una sección? ¡Escríbenos, nosotros te la creamos!",
  wb_employees_select_section_info:
    "Puede editar las descripciones y las fotos de los empleados en el panel de Calendesk.",
  wb_edit_employees_button_title: "Editar empleados",
  wb_services_select_section_info:
    "Puede editar las descripciones de los servicios y sus fotos en el panel de Calendesk.",
  wb_edit_services_button_title: "Editar servicios",
  wb_reload_button_title: "Actualizar",
  wb_category_section_photo_gallery: "Galería",
  wb_hide_arrows: "Ocultar flechas",
  wb_hide_delimiters: "Ocultar interruptores",
  wb_interval: "La tasa de cambio en milisegundos",
  wb_cycle: "Cambio automática",
  wb_invert_colors: "Invertir los colores",
  wb_website_disabled_success:
    "La página ha sido deshabilitada y ya no estará visible en Internet hasta dentro de unos minutos.",
  wb_set_as_main_website: "Establecer como página web principal",
  wb_set_as_main_website_success:
    "La página principal se ha configurado correctamente.",
  wb_main_website: "Página web principal",
  new_content_available:
    "Hay una nueva actualización de la página, haga clic en 'Actualizar' para volver a cargar la página.",
  refresh_button_title: "Actualizar",
  wb_newsletter_input_placeholder: "Sugerencia",
  wb_newsletter_success_message: "El mensaje sobre el envío del enlace.",
  newsletter_delete_success:
    "Los registros seleccionados se han eliminado correctamente",
  remove_newsletter_record: "Eliminar dirección de correo electrónico",
  remove_newsletter_records: "Eliminar direcciones de correo electrónico",
  verified_at: "Comprobado",
  email_is_valid: "Correo electrónico confirmado",
  email_is_not_valid: "Correo electrónico no confirmado",
  newsletters: "Boletín informativo",
  push: "Pulsar",
  push_tooltip: "El usuario utiliza la aplicación móvil",
  send_customer_notification_title: "Envía un mensaje al cliente",
  send_customers_notification_title: "Envía un mensaje a los clientes",
  send_employee_notification_title: "Envía un mensaje al empleado",
  send_employees_notification_title: "Envía un mensaje a los empleados",
  send_customer_notification_number_info: "Número de destinatarios",
  notification_type_1: "Correo electrónico",
  notification_type_2: "SMS",
  notification_type_3: "Notificación  enviadas",
  notification_type_title: "Tipo de notificación",
  notification_category_title: "Categoría de notificación",
  notification_subject: "Título",
  notification_content: "Contenido",
  send: "Enviar",
  send_customer_notification_confirm_title:
    "¿Está seguro de que desea enviar un mensaje a las personas seleccionadas?",
  send_customer_notification_confirm_info:
    'Estás enviando un mensaje del tipo "@{type}". Número de destinatarios: @{amount}.',
  download_csv_title: "Descargar CSV",
  download_vcf_title: "Descargar VCF (vCard)",
  download_xlsx_title: "Descargar XLSX (Microsoft Excel)",
  download_xls_title: "Descargar XLS (Microsoft Excel)",
  download_ods_title: "Descargar ODS (Open Office, LibreOffice)",
  download_html_title: "Descargar HTML",
  customer_notifications: "Historia",
  customer_type: "Destinatario",
  customer_type_1: "Cliente",
  customer_type_2: "Boletín informativo",
  customer_type_3: "Empleado",
  customer_type_4: "Externo",
  customer_type_5: "Empresa",
  recipients_count_title: "Número de destinatarios",
  display_customer_notification_title: "Vista previa del mensaje",
  expand: "Ampliar",
  collapse: "Colapsar",
  reverse_charge_info: "No se añadirá el IVA a la factura (cargo revertido).",
  reverse_charge_info_2: "IVA a pagar por el cobro revertido.",
  employees_premium_banner_title: "Se ha alcanzado el límite",
  employees_premium_banner_description:
    "Actualice a un número ilimitado de empleados.",
  users_premium_banner_title: "Se ha alcanzado el límite",
  users_premium_banner_description:
    "Actualice a un número ilimitado de clientes.",
  google_calendar: "Calendario de Google",
  google_meet: "Reunión de Google",
  google_meet_description:
    "Configure su ubicación de Calendesk en Google Meet para que con cada reserva, Calendesk cree un enlace de reunión único. El enlace se le enviará a usted y a su cliente tan pronto como se realice la reserva.",
  google_calendar_connect_info:
    "Haga clic a continuación para vincular su Google Calendar a Calendesk Calendar. Después de la conexión correcta, todas sus visitas y sus cambios se enviarán al calendario de Google. Recuerde, el calendario funciona de una sola manera. Si cambia su visita a Google Calendar, este cambio no será visible en Calendesk. Realice siempre todos los cambios a través de Calendesk.",
  google_calendar_disconnect_info:
    "La sincronización con el calendario de Google está activa. Haga clic a continuación para desconectarse.",
  disconnect_button_title: "Desconectar",
  plugin_only_for_employees_info:
    "El complemento solo funciona con la cuenta del empleado. Debe crear una cuenta de empleado para poder aceptar reservas.",
  plugin_not_active_employee_info:
    "El complemento no está activo. Póngase en contacto con su administrador para habilitar el complemento.",
  google_calendar_auth_success_title: "Autorización correcta de Google",
  google_calendar_disconnect_dialog_title: "Desconectar Google Calendar",
  google_calendar_disconnect_dialog_info:
    "¿Seguro que quieres desconectar tu Google Calendar? Sus futuras visitas y bloqueos de Calendesk ya no se enviarán a su Calendario de Google.",
  google_calendar_disconnect_success_message:
    "El calendario se ha desconectado correctamente.",
  buy: "Comprar",
  on_time_payment: "Pago único",
  plan_expired_title: "Su plan actual ha expirado",
  building_plans_title:
    "¡Activamos todas las funciones que necesitas para ti &#128640;&#128640;!",
  building_plans_subtitle: "No tardará más de unos segundos...",
  go_to_summary: "Ir al resumen",
  cd_base_standard: "Calendesk Standard",
  cd_base_pro: "Calendesk Pro",
  cd_base_enterprise: "Calendesk Enterprise",
  plans_description_standard: "Aprovecha Calendesk para mejorar tu negocio.",
  plans_description_pro:
    "Para los más exigentes y enfocados en el  crecimiento.",
  plans_description_enterprise:
    "Flexibilidad total, contáctenos para más información.",
  enterprise_price_list_info: "Contrato individual con facturación anual",
  plans_billed_annually: "facturado anualmente",
  plans_or: "o",
  plans_month_to_month_payment: "facturado mensualmente",
  plans_featured: "Más Popular",
  plans_feature_name_1: "Tu dirección web",
  plans_feature_lite_1: "Subdominio principal: calendesk.net",
  plans_feature_lite_1_tooltip:
    "No es posible configurar su propio dominio, la dirección de su sitio web se verá así: www.companyname.calendsk.net.",
  plans_feature_standard_1: "Dominio propio",
  plans_feature_standard_1_tooltip:
    "Posibilidad de configurar cualquier dirección web después de comprar un dominio en el servicio de alojamiento.",
  plans_feature_pro_1: "Dominio propio",
  plans_feature_pro_1_tooltip:
    "Posibilidad de configurar cualquier dirección web después de comprar un dominio en el servicio de alojamiento.",
  plans_feature_name_2: "Número de sitios web",
  plans_feature_standard_2: "1 página como máximo",
  plans_feature_standard_2_tooltip:
    "Posibilidad de crear 1 sitio web con cualquier número de subpáginas para su negocio.",
  plans_feature_pro_2: "3 página como máximo",
  plans_feature_pro_2_tooltip:
    "Posibilidad de crear 3 páginas diferentes con varias subpáginas para los diferentes servicios que ofrece (por ejemplo, en SEO y SEM).",
  plans_feature_enterprise_2: "Ilimitado",
  plans_feature_enterprise_2_tooltip:
    "Posibilidad de crear varias  páginas para diferentes servicios y diferentes clientes, por ejemplo, sitios web para clientes individuales, para negocios, publicidad de un producto o servicio específico, páginas de Facebook y páginas especiales para motores de búsqueda (SEO y SEM).",
  plans_feature_name_3: "Creador de sitios web",
  plans_feature_standard_3: "Sí",
  plans_feature_standard_3_tooltip:
    "Posibilidad de crear varias secciones en la páginas, acceso a una amplia colección de gráficos y posibilidad de personalizar la página.",
  plans_feature_pro_3: "Sí",
  plans_feature_pro_3_tooltip:
    "Posibilidad de crear varias secciones en la página, acceso a una amplia colección de gráficos y posibilidad de personalizar la página.",
  plans_feature_enterprise_3: "Sí",
  plans_feature_enterprise_3_tooltip:
    "Posibilidad de crear varias secciones en la página, acceso a una amplia colección de gráficos y posibilidad de personalizar la página.",
  plans_feature_name_4: "Aplicación móvil para clientes.",
  plans_feature_standard_4: "Sí",
  plans_feature_standard_4_tooltip:
    "La posibilidad de crear una aplicación móvil simple para sus clientes: haga que sea lo más fácil posible de esta forma sus  clientes se comunicaran mejor entre sí y utilizar sus servicios.",
  plans_feature_pro_4: "Sí",
  plans_feature_pro_4_tooltip:
    "Posibilidad de crear una aplicación móvil simple o una versión personalizada avanzada, totalmente adaptada a usted y sus expectativas.",
  plans_feature_enterprise_4: "Sí",
  plans_feature_enterprise_4_tooltip:
    "Posibilidad de crear una aplicación móvil simple o una versión personalizada avanzada, totalmente adaptada a usted y sus expectativas.",
  plans_feature_name_5: "Notificaciónes de Correo Electrónico",
  plans_feature_standard_5: "500 piezas por mes",
  plans_feature_standard_5_tooltip:
    "Envía a tus clientes e-mails de reservas (confirma que la fecha ha sido aceptada, cambiada o cancelada), e-mails de transacciones, notificaciones y ofertas especiales.",
  plans_feature_pro_5: "1,000 visitas por mes",
  plans_feature_pro_5_tooltip:
    "Envía a tus clientes e-mails de reservas (confirma que la fecha ha sido aceptada, cambiada o cancelada), e-mails de transacciones, notificaciones y ofertas especiales.",
  plans_feature_enterprise_5: "Ilimitado",
  plans_feature_enterprise_5_tooltip:
    "Envía a tus clientes e-mails de reservas (confirma que la fecha ha sido aceptada, cambiada o cancelada), e-mails de transacciones, notificaciones y ofertas especiales.",
  plans_feature_name_6: "Ampliación del paquete de correo electrónico",
  plans_feature_standard_6: "Sí",
  plans_feature_standard_6_tooltip:
    "Posibilidad de comprar todos los que quieras a 8 gr por correo electrónico.",
  plans_feature_pro_6: "Sí",
  plans_feature_pro_6_tooltip:
    "Posibilidad de adquirir cualquier cantidad por 1 centavo por e-mail.",
  plans_feature_enterprise_6: "Sí",
  plans_feature_enterprise_6_tooltip:
    "Posibilidad de comprar cualquier cantidad  de forma individual.",
  plans_feature_name_7: "Notificaciones SMS",
  plans_feature_standard_7: "50 visitas por mes",
  plans_feature_standard_7_tooltip:
    "Envíe mensajes SMS a sus clientes: confirme la reserva, modificación o cancelación, informe sobre promociones, ofertas especiales y descuentos.",
  plans_feature_pro_7: "100 visitas por mes",
  plans_feature_pro_7_tooltip:
    "Envíe mensajes SMS a sus clientes: confirme la reserva, modificación o cancelación, informe sobre promociones, ofertas especiales y descuentos.",
  plans_feature_enterprise_7: "Ilimitado",
  plans_feature_enterprise_7_tooltip:
    "Envíe mensajes SMS a sus clientes: confirme la reserva, modificación o cancelación, informe sobre promociones, ofertas especiales y descuentos.",
  plans_feature_name_8: "Ampliación del paquete de mensajes SMS",
  plans_feature_standard_8: "Sí",
  plans_feature_standard_8_tooltip:
    "Tienes la posibilidad de comprar todos los que quieras a 7 centavos por SMS + paquete especial de 200 unidades por mes por 7,99 EUR.",
  plans_feature_pro_8: "Sí",
  plans_feature_pro_8_tooltip:
    "Tienes la posibilidad de comprar todos los que quieras a 6 centavos por SMS + paquete PREMIUM de 250 unidades por mes por 7,99 EUR.",
  plans_feature_enterprise_8: "Sí",
  plans_feature_enterprise_8_tooltip:
    "Posibilidad de comprar cualquier cantidad  de forma individual.",
  plans_feature_name_10: "Pagos en línea y suscripciones",
  plans_feature_standard_10: "Sí",
  plans_feature_standard_10_tooltip:
    "Realice los pagos en línea, de forma rápida, cómoda y segura. No te preocupes por los cambios y las reservas vacías con las que no ganas nada.",
  plans_feature_pro_10: "Sí",
  plans_feature_pro_10_tooltip:
    "Realice los pagos en línea, de forma rápida, cómoda y segura. No te preocupes por los cambios y las reservas vacías con las que no ganas nada.",
  plans_feature_enterprise_10: "Sí",
  plans_feature_enterprise_10_tooltip:
    "Realice los pagos en línea, de forma rápida, cómoda y segura. No te preocupes por los cambios y las reservas vacías con las que no ganas nada.",
  plans_feature_name_11: "Sistema de reservas",
  plans_feature_standard_11: "Sí",
  plans_feature_standard_11_tooltip:
    "Opción completa de reserva en línea con la posibilidad de que el cliente escriba y deje un comentario.",
  plans_feature_pro_11: "Sí",
  plans_feature_pro_11_tooltip:
    "Opción completa de reserva en línea con la posibilidad de que el cliente escriba y deje un comentario.",
  plans_feature_enterprise_11: "Sí",
  plans_feature_enterprise_11_tooltip:
    "Opción completa de reserva en línea con la posibilidad de que el cliente escriba y deje un comentario.",
  plans_feature_name_12: "Integración de Google Calendar",
  plans_feature_standard_12: "Sí",
  plans_feature_standard_12_tooltip:
    "Controla los eventos en tiempo real, exporta todas las citas, pedidos y reuniones a Google Calendar. Hágalos visibles para sus empleados y clientes. Facilíteles la vida al máximo y aumente la eficacia de su empresa.",
  plans_feature_pro_12: "Sí",
  plans_feature_pro_12_tooltip:
    "Controla los eventos en tiempo real, exporta todas las citas, pedidos y reuniones a Google Calendar. Hágalos visibles para sus empleados y clientes. Facilíteles la vida al máximo y aumente la eficacia de su empresa.",
  plans_feature_enterprise_12: "Sí",
  plans_feature_enterprise_12_tooltip:
    "Controla los eventos en tiempo real, exporta todas las citas, pedidos y reuniones a Google Calendar. Hágalos visibles para sus empleados y clientes. Facilíteles la vida al máximo y aumente la eficacia de su empresa.",
  plans_feature_name_14: "Boletín informativo",
  plans_feature_standard_14: "Sí",
  plans_feature_standard_14_tooltip:
    "Crea un boletín simple para el cliente: recordandole tus servicios, ofreceles bonos, descuentos, promociones y servicios adicionales.",
  plans_feature_pro_14: "Sí",
  plans_feature_pro_14_tooltip:
    "Crea un boletín simple para el cliente: recordandole tus servicios, ofreceles bonos, descuentos, promociones y servicios adicionales.",
  plans_feature_enterprise_14: "Sí",
  plans_feature_enterprise_14_tooltip:
    "Crea un boletín simple para el cliente: recordandole tus servicios, ofreceles bonos, descuentos, promociones y servicios adicionales.",
  plans_feature_name_18: "Número de reservas",
  plans_feature_standard_18: "750 reservas por mes",
  plans_feature_standard_18_tooltip: "Gestión de hasta 750 reservas por mes.",
  plans_feature_pro_18: "2.500 reservas por mes",
  plans_feature_pro_18_tooltip: "Gestión de hasta 2.500 reservas al mes.",
  plans_feature_enterprise_18: "Ilimitado",
  plans_feature_enterprise_18_tooltip: "Sin límite de reservas ",
  plans_feature_name_19: "Número de cuentas de empleados",
  plans_feature_standard_19: "5 cuentas de empleados",
  plans_feature_standard_19_tooltip:
    "Limitado a 5 usuarios de la aplicación: la capacidad de establecer roles básicos y niveles de acceso.",
  plans_feature_pro_19: "15 cuentas de empleados",
  plans_feature_pro_19_tooltip:
    "Limitado a 15 usuarios de la aplicación: la capacidad de establecer roles básicos y niveles de acceso.",
  plans_feature_enterprise_19: "Ilimitado",
  plans_feature_enterprise_19_tooltip:
    "No hay límite en la cantidad de cuentas admitidas: capacidad ilimitada para definir roles y múltiples niveles de acceso. Número de cuentas compatibles configuradas individualmente.",
  plans_feature_name_20: "Política de privacidad y normativa",
  plans_feature_standard_20: "Si",
  plans_feature_standard_20_tooltip:
    "Genere documentos utilizando la aplicación desarrollada junto con nuestros abogados.",
  plans_feature_pro_20: "Sí",
  plans_feature_pro_20_tooltip:
    "Genere documentos utilizando la aplicación desarrollada junto con nuestros abogados.",
  plans_feature_enterprise_20: "Si",
  plans_feature_enterprise_20_tooltip:
    "Genere documentos utilizando la aplicación desarrollada junto con nuestros abogados.",
  plans_feature_name_21: "Integración de Google Meet",
  plans_feature_standard_21: "Si",
  plans_feature_standard_21_tooltip:
    "Genere un enlace a una reunión de Google Meet inmediatamente después de la reserva.",
  plans_feature_pro_21: "Si",
  plans_feature_pro_21_tooltip:
    "Genere un enlace a una reunión de Google Meet inmediatamente después de la reserva.",
  plans_feature_enterprise_21: "Si",
  plans_feature_enterprise_21_tooltip:
    "Genere un enlace a una reunión de Google Meet inmediatamente después de la reserva.",
  plans_feature_name_22: "Integración de Bitfactura",
  plans_feature_standard_22: "Sí",
  plans_feature_standard_22_tooltip:
    "Emita una factura con IVA a su cliente inmediatamente después del pago.",
  plans_feature_pro_22: "Sí",
  plans_feature_pro_22_tooltip:
    "Emita una factura con IVA a su cliente inmediatamente después del pago.",
  plans_feature_enterprise_22: "Sí",
  plans_feature_enterprise_22_tooltip:
    "Emita una factura con IVA a su cliente inmediatamente después del pago.",
  plans_feature_name_23: "Integración de Zoom",
  plans_feature_standard_23: "Sí",
  plans_feature_standard_23_tooltip:
    "Genere un enlace de reunión de Zoom inmediatamente después de la reserva.",
  plans_feature_pro_23: "Sí",
  plans_feature_pro_23_tooltip:
    "Genera un enlace de reunión de Zoom inmediatamente después de la reserva.",
  plans_feature_enterprise_23: "Sí",
  plans_feature_enterprise_23_tooltip:
    "Genera un enlace de reunión de Zoom inmediatamente después de la reserva.",
  plans_feature_name_24: "Integración de Zapier",
  plans_feature_standard_24: "No",
  plans_feature_standard_24_tooltip:
    "La opción está disponible desde el plan tarifario Pro.",
  plans_feature_pro_24: "Sí",
  plans_feature_pro_24_tooltip:
    "Conecte sus aplicaciones y automatice los flujos de trabajo.",
  plans_feature_enterprise_24: "Sí",
  plans_feature_enterprise_24_tooltip:
    "Conecte sus aplicaciones y automatice los flujos de trabajo.",
  plans_feature_name_25: "Acceso  API",
  plans_feature_standard_25: "No",
  plans_feature_standard_25_tooltip:
    "La opción está disponible desde el plan tarifario Pro.",
  plans_feature_pro_25: "Sí",
  plans_feature_pro_25_tooltip:
    "Utilice los datos recopilados en Calendesk en cualquier aplicación con la que trabaje.",
  plans_feature_enterprise_25: "Sí",
  plans_feature_enterprise_25_tooltip:
    "Utilice los datos recopilados en Calendesk en cualquier aplicación con la que trabaje.",
  plans_feature_name_26: "Widget del calendario",
  plans_feature_standard_26: "Sí",
  plans_feature_standard_26_tooltip:
    "Posibilidad de conectar el sistema de reservas en su propia web.",
  plans_feature_pro_26: "Sí",
  plans_feature_pro_26_tooltip:
    "Posibilidad de conectar el sistema de reservas en su propia web.",
  plans_feature_enterprise_26: "Sí",
  plans_feature_enterprise_26_tooltip:
    "Posibilidad de conectar el sistema de reservas en su propia web.",
  plans_feature_name_27: "Número de visitas de la páginas por mes",
  plans_feature_standard_27: "5,000 visitas al mes",
  plans_feature_standard_27_tooltip:
    "5.000 visitas por mes en tu web o calendario.",
  plans_feature_pro_27: "10,000 visitas al mes",
  plans_feature_pro_27_tooltip:
    "10.000 visitas por mes en tu web o calendario.",
  plans_feature_enterprise_27: "Ilimitado",
  plans_feature_enterprise_27_tooltip:
    "El número de visitas  se determina individualmente.",
  plans_feature_name_28: "Integración de Microsoft Outlook",
  plans_feature_standard_28: "Sí",
  plans_feature_pro_28: "Sí",
  plans_feature_standard_28_tooltip:
    "Controla los eventos en tiempo real, exporta todas las citas, pedidos y reuniones a Outlook Calendar. Hágalos visibles para sus empleados y clientes. Facilíteles la vida al máximo y aumente la eficacia de su empresa.",
  plans_feature_pro_28_tooltip:
    "Controla los eventos en tiempo real, exporta todas las citas, pedidos y reuniones a Outlook Calendar. Hágalos visibles para sus empleados y clientes. Facilíteles la vida al máximo y aumente la eficacia de su empresa.",
  plans_feature_enterprise_28: "Sí",
  plans_feature_enterprise_28_tooltip:
    "Controla los eventos en tiempo real, exporta todas las citas, pedidos y reuniones a Outlook Calendar. Hágalos visibles para sus empleados y clientes. Facilíteles la vida al máximo y aumente la eficacia de su empresa.",
  plans_feature_name_29: "Integración de Microsoft Teams",
  plans_feature_standard_29: "Sí",
  plans_feature_standard_29_tooltip:
    "Genera un enlace de reunión de Teams inmediatamente después de la reserva.",
  plans_feature_pro_29: "Sí",
  plans_feature_pro_29_tooltip:
    "Genera un enlace de reunión de Teams inmediatamente después de la reserva.",
  plans_feature_enterprise_29: "Sí",
  plans_feature_enterprise_29_tooltip:
    "Genera un enlace de reunión de Teams inmediatamente después de la reserva.",
  plans_feature_name_30: "Integración de Skype",
  plans_feature_standard_30: "Sí",
  plans_feature_standard_30_tooltip:
    "Genera un enlace de reunión de Skype inmediatamente después de la reserva.",
  plans_feature_pro_30: "Sí",
  plans_feature_pro_30_tooltip:
    "Genera un enlace de reunión de Skype inmediatamente después de la reserva.",
  plans_feature_enterprise_30: "Sí",
  plans_feature_enterprise_30_tooltip:
    "Genera un enlace de reunión de Skype inmediatamente después de la reserva.",
  plans_feature_name_31: "Integración de WhatsApp",
  plans_feature_standard_31: "Sí",
  plans_feature_standard_31_tooltip:
    "Genera un enlace de reunión de WhatsApp inmediatamente después de la reserva.",
  plans_feature_pro_31: "Sí",
  plans_feature_pro_31_tooltip:
    "Genera un enlace de reunión de WhatsApp inmediatamente después de la reserva.",
  plans_feature_enterprise_31: "Sí",
  plans_feature_enterprise_31_tooltip:
    "Genera un enlace de reunión de WhatsApp inmediatamente después de la reserva.",
  plans_feature_name_32: "Recordatorios avanzados",
  plans_feature_standard_32: "No",
  plans_feature_standard_32_tooltip:
    "Recordatorios básicos en forma de correos electrónicos, SMS y notificaciones push, que se pueden configurar en cualquier momento.",
  plans_feature_pro_32: "Sí",
  plans_feature_pro_32_tooltip:
    "Recordatorios avanzados y precisos que permiten especificar qué recordatorios se envían, a quién y cuándo.",
  plans_feature_enterprise_32: "Sí",
  plans_feature_enterprise_32_tooltip:
    "Recordatorios avanzados y precisos que permiten especificar qué recordatorios se envían, a quién y cuándo.",
  plans_trial_faq_2_title:
    "¿Puedo darme de baja de Calendesk en cualquier momento?",
  plans_trial_faq_2_description_1:
    "Si quieres darte de baja, lamentaremos dejarte, pero por supuesto <b>puedes hacerlo cuando quieras</b> . Calendesk no le obliga a ningún contrato a largo plazo. Puedes usar Calendesk pagándo al mes o al año (obtienes un 20% de descuento en los pagos anuales).",
  plans_trial_faq_3_title: "¿Puedo cambiar mi plan en cualquier momento?",
  plans_trial_faq_3_description_1:
    "Sí, por supuesto. <b>Puede migrar entre planes en cualquier momento.</b> Nuestro operador de pagos recalculará los costes para que no pierdas nada en dicha migración. Si lo realizas durante el mes en cuerso, solo se le cobrará por el resto del período de facturación. Si baja de categoría en el mismo mes, utilizaremos los costes restante para pagos futuros o le reembolsaremos su dinero.",
  plans_trial_faq_4_title:
    "¿Qué ocurre cuando termina el periodo de vigencia del contrato? ¿La aplicación se renovará automáticamente y cobrará una tasa adicional por todo el periodo?\n",
  plans_trial_faq_4_description_1:
    'Sí, el plan seleccionado se renueva automáticamente. Si no quieres que el plan se renueve en el nuevo periodo, puedes cancelarlo en cualquier momento en la pestaña "Cuentas y pagos".\n',
  plans_trial_faq_5_title:
    "¿Perderé mi cuenta y mis datos si olvido extender mi período de uso de Calendesk?",
  plans_trial_faq_5_description_1:
    "Sus datos seguirán estando disponibles en nuestro sistema durante algún tiempo, para que pueda recuperar el acceso a su cuenta y al proyecto que ha creado de forma segura.",
  plans_trial_faq_5_description_2:
    "Después de la expiración del período del contrato y el bloqueo de la cuenta aunque sea por unos días, todavía tiene la opción de recuperarla, siempre que realice un pago.",
  plans_trial_faq_5_description_3:
    "Si esto le resulta difícil o poco claro, póngase en contacto con nuestro equipo de asistencia.\n",
  plans_trial_faq_6_title: "¿Cómo sabré cuándo está finalizando mi plan?",
  plans_trial_faq_6_description_1:
    "Después de iniciar sesión en su cuenta de Calendesk, aparecerá en la esquina derecha el nombre de su plan de tarifas y el tiempo restante hasta que caduque.",
  plans_trial_faq_6_description_2:
    "Poco antes de que caduque su plan, también recibirá una notificación emergente justo después de iniciar sesión y le enviaremos un correo electrónico.",
  plan_selected_title:
    "¡Felicidades! Ha seleccionado el plan de llamadas @{plan}.",
  plan_selected_subtitle_1:
    "¡Adáptalo a las necesidades de tu empresa seleccionando paquetes adicionales!",
  plan_selected_subtitle_2:
    "Puede activarlos y desactivarlos en cualquier momento en el menú de la aplicación.",
  "plugins_sms-notifications_cd_base_lite_name": "Paquete de contacto",
  "plugins_sms-notifications_cd_base_lite_description":
    "Envíe a sus clientes notificaciones por SMS de forma sencilla y rápida. Confirme las reservas, recuerde las citas y acepte los cambios. El paquete incluye 150 mensajes SMS, con la opción de adquirir otros nuevos de forma recurrente.",
  "plugins_sms-notifications_cd_base_standard_name": "Paquete Contacto Pro",
  "plugins_sms-notifications_cd_base_standard_description":
    "Puedes enviar SMS en mayor cantidad a todos sus clientes. Enviales mensajes especiales, promociones y descuentos por su cumpleaños. Motivales a ponerse en contacto y a visitar su sitio web. Este pack incluye 200 mensajes SMS con la opción de ampliar esta cantidad realizando una compra.",
  "plugins_sms-notifications_cd_base_pro_name": "Paquete contacto Expert",
  "plugins_sms-notifications_cd_base_pro_description":
    "Envíe a sus clientes notificaciones por SMS en mayor número. Recuérdales con mensajes especiales, promociones, ocasiones y descuentos por cumpleaños. Anímelos a ponerse en contacto con usted y a visitar su sitio web. El paquete contiene 250 mensajes SMS con la posibilidad de comprar nuevos de forma recurrente.\n",
  "plugins_mobile-app-staff_cd_base_lite_name":
    "Aplicación móvil para empleados.",
  "plugins_mobile-app-staff_cd_base_lite_description":
    "Ofrezca a sus empleados la posibilidad de utilizar una aplicación móvil específica en sus teléfonos inteligentes. Permita que vean el calendario, que comprueben las tareas que se les han asignado para el día y los clientes a los que van a atender. Con las notificaciones  automáticas en sus teléfonos, sus empleados recibirán al instante toda la información que necesiten de usted.",
  plugins_booking_cd_base_lite_name: "Paquete de reserva",
  plugins_booking_cd_base_lite_description:
    "Añada a su aplicación la posibilidad de crear un sistema de reservas totalmente funcional para los clientes. Ofrézcales la posibilidad de elegir opciones de servicio específicas (por ejemplo, lavado, corte, peinado) y el acceso a los horarios de su personal: puediendo elegir a quién quieren contratar.\n",
  "plugins_google-calendar_cd_base_standard_name": "Calendario de Google",
  "plugins_google-calendar_cd_base_standard_description":
    "Controla los eventos en tiempo real, exporta todas las citas, pedidos y reuniones a Google Calendar. Hágalos visibles para sus empleados y clientes. Facilíteles la vida al máximo y aumente la eficacia de su empresa. Crea recordatorios, listas de tareas y un planificador diario: añádeles eventos de Facebook e invita a tus clientes.\n",
  plugins_mobile_cd_base_lite_name: "Paquete móvil",
  plugins_mobile_cd_base_lite_description:
    "Haz que tu web esté totalmente adaptada al uso de dispositivos móviles (smartphones y tablets). Gracias a este paquete, obtendrá fácilmente una compatibilidad total.",
  "plugins_mobile-app-customers-pro_cd_base_standard_name":
    "Paquete móvil profesional",
  "plugins_mobile-app-customers-pro_cd_base_standard_description":
    "Crea una aplicación de empresa totalmente profesional para dispositivos móviles. Permita que sus clientes descarguen una aplicación con su logotipo y contenido que usted mismo elige. Considere el diseño, defina el estilo, el color y el tipo de fuentes. Elija las funcionalidades y el alcance de los servicios ofrecidos: decida usted mismo desde el principio hasta el final.",
  "plugins_mobile-app-customers-pro_cd_base_pro_name": "Paquete Experto Móvil",
  "plugins_mobile-app-customers-pro_cd_base_pro_description":
    "Crea una aplicación de empresa totalmente profesional para dispositivos móviles. Permita que sus clientes descarguen una aplicación con su logotipo y contenido que usted mismo elige. Considere el diseño, defina el estilo, el color y el tipo de fuentes. Elija las funcionalidades y el alcance de los servicios ofrecidos: decida usted mismo desde el principio hasta el final.",
  plugins_management_cd_base_lite_name: "Paquete de gestión",
  plugins_management_cd_base_lite_description:
    "Desbloquee el acceso ilimitado a la base de conocimientos de marketing. Aprenda consejos eficaces, sencillos y fáciles de aplicar que le ayudarán a sacar más provecho de Calendesk y a hacer crecer su negocio. Lea regularmente artículos, comentarios y estudios, y descubra los hechos relevantes.\n",
  plugins_support_cd_base_standard_name: "Atención individual de expertos",
  plugins_support_cd_base_standard_description:
    "Añada la posibilidad de asistencia profesional a su solicitud. Su asesor técnico individual, le ayudará a resolver cualquier problema, le mostrará el mejor curso de acción y le sugerirá cómo evitar problemas en el futuro.\n",
  "plugins_support-expert_cd_base_pro_name":
    "Atención individual de expertos +",
  "plugins_support-expert_cd_base_pro_description":
    "Paquete individual de atención experta prioritaria. Añada a su solicitud la posibilidad de asistencia profesional PREMIUM. Su asesor técnico individual, le ayudará a resolver cualquier problema de forma prioritaria. Estará a su disposición durante el periodo de prórroga y resolverá cada asunto de forma rápida.\n",
  "plugins_easy-going_cd_base_standard_name": "Paquete Easy Going",
  "plugins_easy-going_cd_base_standard_description":
    "Atienda a un número ilimitado de clientes, desbloquee toda la capacidad operativa de Calendesk, deje que la aplicación le ayude a hacer crecer su negocio y aumentar sistemáticamente sus beneficios.",
  "plugins_alphanumeric-sms-sender_cd_base_standard_name":
    "Nombre propio del remitente del SMS",
  "plugins_alphanumeric-sms-sender_cd_base_standard_description":
    "Puedes personalizar los SMS utilizando su propio nombre como remitente, en los mensajes a sus clientes, sustituyendo así el aburrido numero de teléfono y mostrado su lado mas original. Puede establecer su propio nombre en el Perfil -> Su empresa.",
  "plugins_alphanumeric-sms-sender_cd_base_pro_name":
    "Nombre propio del remitente del SMS",
  "plugins_alphanumeric-sms-sender_cd_base_pro_description":
    "Puedes personalizar los SMS utilizando su propio nombre como remitente, en los mensajes a sus clientes, sustituyendo así el aburrido numero de teléfono y mostrado su lado mas original. Puede establecer su propio nombre en el Perfil -> Su empresa.",
  plans_thank_you_page_title:
    "¡Tu plan y paquetes adicionales seleccionados han sido activados!",
  plans_thank_you_page_title_part_1:
    "Su plan y los paquetes adicionales seleccionados han sido activados",
  plans_thank_you_page_title_part_2: "¡Activado!",
  plans_thank_you_page_subtitle:
    'Puede activarlos o desactivarlos en cualquier momento en la pestaña "Tus paquetes"',
  plans_thank_you_page_button: "Comience con Calendesk",
  fill_invoice_data: "Complete los detalles de la factura.",
  cancel_plan_confirm_button_1: "Bueno me quedo!",
  cancel_plan_confirm_button_2: "Cancelar plan :(",
  purchase_remove: "Borrar",
  wb_align_center: "Alinear al centro",
  wb_container_fluid: "Ancho total de la sección",
  wb_image_to_right: "Foto a la derecha",
  wb_image_width: "El ancho de la imagen en píxeles, por ejemplo, 200",
  wb_image_height: "Altura de la imagen en píxeles, por ejemplo, 200",
  wb_button_width: "El ancho del botón en píxeles, por ejemplo, 200",
  wb_image_contain: "Ajustar la imagen completa",
  wb_button_elevation: "Sombra (nivel) por ejemplo, 3",
  wb_height: "Altura de la sección",
  wb_image_hide_on_mobile: "Ocultar foto en teléfonos inteligentes",
  wb_buttons_position_title: "Posición del botón",
  wb_form_position_title: "Posición del formulario",
  wb_image_position_title: "Posición de la foto",
  wb_position_top_right_title: "Lado superior derecho",
  wb_position_top_left_title: "Lado superior izquierdo",
  wb_position_top_center_title: "Medio superior",
  wb_position_bottom_right_title: "Por el lado derecho",
  wb_position_bottom_left_title: "Lado inferior izquierdo",
  wb_position_bottom_center_title: "Por la mitad",
  wb_create_new_website: "Empezar de nuevo",
  wb_image_hide: "Ocultar foto",
  stripe_is_not_available_in_your_plan:
    "Los pagos con Stripe no están disponibles en el plan Lite. Actualice a Estándar o Pro.",
  fakturownia_is_not_available_in_your_plan:
    "La facturación no está disponible en el plan Lite. Actualice a Estándar o Pro.",
  help: "Ayuda",
  upselling: "Venta adicional",
  simple_store_product_list: "Productos",
  simple_store_product_transaction_list: "Transacciones",
  simple_store_product_list_product_name: "Nombre",
  simple_store_product_list_product_price: "Precio",
  no_simple_store_product_info:
    "Aún no tiene ningún producto agregado, haga clic a continuación para agregar el primero.",
  no_subscriptions_info:
    "Aún no tiene suscripciones agregadas, haga clic a continuación para agregar la primera.",
  simple_store_product_add_title: "Añadir un producto",
  subscriptions_add_title: "Añadir una suscripción",
  subscriptions_update_title: "Actualizar suscripción",
  no_simple_store_product_transaction_info:
    "Aún no has registrado la venta de ningún producto. Si ya se han agregado productos, puedes asignar la venta de cualquiera de ellos a un cliente seleccionado a continuación.",
  simple_store_product_update_title: "Editar el producto",
  wb_button_edit_menu_simple_store_product: "Elige un producto",
  wb_button_edit_menu_booking: "Elige un servicio",
  wb_cta_button_type_1: "Reenvío",
  wb_cta_button_type_2: "Reserva",
  wb_cta_button_type_3: "Producto",
  wb_cta_button_type_4: "Suscripción",
  simple_store_product: "Producto",
  booking_payments: "Reservas",
  payment_type_select_box: "Seleccionar forma de pago",
  wb_round_zero_title: "No",
  wb_round_sm_title: "Pequeño",
  wb_round_md_title: "Medio",
  wb_round_lg_title: "Grande",
  wb_round_xl_title: "SG",
  wb_round_pill_title: "Tableta",
  wb_round_circle_title: "Circulo",
  wb_image_rounded: "Redondea la foto",
  wb_gallery_image_number: "Número de fotos en la galería (ordenador)",
  wb_gallery_mobile_image_number: "Número de fotos en la galería (smartphone)",
  wb_gallery_width: "El ancho de la galería en porcentaje, por ejemplo, 50",
  wb_gallery_height: "La altura de la galería en píxeles, por ejemplo, 200",
  wb_gallery_distance: "Intervalo entre fotos",
  wb_image_zoom_on_click: "Ampliar la foto después de hacer clic",
  wb_gallery_style: "Estilo de galería",
  wb_gallery_style_1_title: "Estilo 1",
  wb_gallery_style_2_title: "Estilo 2",
  wb_gallery_style_3_title: "Estilo 3",
  help_center_title:
    "Obtenga más información sobre las funciones de Calendesk viendo videos cortos.",
  help_center_group_1: "Introducción",
  help_center_group_2: "Sistema de reservación",
  help_center_group_3: "Clientes y notificaciones",
  help_center_group_4: "Creador de sitios web",
  help_center_group_5: "Aplicación movil",
  help_center_group_6: "Pago",
  help_center_group_1_video_1_title:
    "Introducción a Calendesk: una descripción general rápida de todas las funciones",
  help_center_group_1_video_2_title: "Completa los datos de tu empresa",
  help_center_group_2_video_1_title:
    "Empleados: ¿cómo agregar una nueva persona que aceptará reservas?",
  help_center_group_2_video_2_title:
    "Servicios: ¿qué son y cómo agregar un nuevo servicio al sistema de reservas?",
  help_center_group_2_video_3_title:
    "Servicios: ¿cómo administrar la lista de servicios?",
  help_center_group_2_video_4_title:
    "Calendario: ¿cómo gestionar el calendario de reservas?",
  help_center_group_2_video_5_title:
    "Calendario - eventos no relacionados con la visita del cliente",
  help_center_group_2_video_6_title:
    "Calendario: limitaciones del calendario del cliente",
  help_center_group_3_video_1_title:
    "¿Cómo gestionar clientes en Calendesk? Envío de notificaciones a clientes y newsletter.",
  help_center_group_3_video_2_title:
    "Personalización de e-mail, SMS y notificaciones automáticas",
  help_center_group_4_video_1_title:
    "Sitio web propio: introducción al asistente de Calendesk",
  help_center_group_4_video_2_title:
    "Sitio web propio - funcionalidades básicas",
  help_center_group_4_video_3_title:
    "Sitio web propio - encabezado y pie de página",
  help_center_group_4_video_4_title:
    "Sitio web propio - reemplazo de encabezado y pie de página",
  help_center_group_4_video_5_title:
    "Sitio web propio: ¿cómo agregar secciones?",
  help_center_group_4_video_6_title:
    "Sitio web propio: ¿cómo establecer un dominio?",
  help_center_group_5_video_1_title:
    "Aplicación móvil con el sistema de reservas: descripción general del asistente",
  help_center_group_5_video_2_title:
    "Aplicación móvil con sistema de reservas - presentación de la aplicación en App Store o Google Play",
  help_center_group_6_video_1_title:
    "¿Cómo conecto los pagos de Stripe con mi sistema de reservas y mi tienda?",
  help_center_group_6_video_2_title: "¿Cómo habilitar Przelewy24 en Stripe?",
  help_center_group_6_video_3_title: "¿Cómo cobro en línea por sus servicios?",
  help_center_group_6_video_4_title:
    "Tienda en línea simple en Calendesk: ¿cómo cobrar por los productos en línea?",
  help_center_group_1_video_1_description:
    "En este vídeo descubrirás qué posibilidades ofrece Calendesk. Aquí presentaremos la estructura de toda la plataforma. En esta guía, aprenderá a navegar por los módulos individuales de Calendesk.",
  help_center_group_1_video_2_description:
    "Los datos de su empresa pueden aparecer en su sitio web y en su aplicación móvil. En esta sección, aprenderá dónde rellenar los datos de su empresa, dónde establecer el horario de apertura de su centro de trabajo, dónde añadir los enlaces a las redes sociales y cómo generar los Términos y Condiciones y la Política de Privacidad.\n ",
  help_center_group_2_video_1_description:
    "En este vídeo aprenderás a configurar un sistema de reservas. Aquí le contaremos más sobre cómo añadir empleados, darles permisos y configurar sus cuentas en Calendesk.",
  help_center_group_2_video_2_description:
    "En esta parte de la guía, le diremos cómo añadir y configurar servicios. Aquí aprenderá a añadir tiempo a los servicios, a asignarles precios y a describirlos.\n",
  help_center_group_2_video_3_description:
    "La lista de servicios es el lugar desde donde puedes gestionar fácilmente tus servicios y añadir otros nuevos.\n",
  help_center_group_2_video_4_description:
    "Aprende a usar el calendario de Calendesk aquí. En este vídeo explicamos cómo filtrar reservas mediante la selección de empleados y cómo gestionar las reservas.",
  help_center_group_2_video_5_description:
    '¿Qué hacer cuando un empleado se va de vacaciones? En este vídeo aprenderá a utilizar la casilla "Evento no relacionado con la visita de un cliente".',
  help_center_group_2_video_6_description:
    "Los derechos de administrador le brindan posibilidades ilimitadas en términos de administración del calendario. Descubre más en este vídeo.",
  help_center_group_3_video_1_description:
    "En esta parte aprenderá qué herramientas le permitirán mantener una buena relación con el cliente y cómo gestionar clientes en la plataforma Calendesk.",
  help_center_group_3_video_2_description:
    "Aprende a usar las notificaciones y cómo modificarlas. ¡Es una gran herramienta para comunicarse con clientes y empleados!",
  help_center_group_4_video_1_description:
    "Consulta cómo navegar en nuestro editor de sitios web para crear una web totalmente personalizada en la que tus clientes reservarán visitas contigo.",
  help_center_group_4_video_2_description:
    "Vea cómo se personaliza su sitio web. ¡Construir tu imagen en Internet puede ser mucho más divertido de lo que pensabas!",
  help_center_group_4_video_3_description:
    "Obtenga más información sobre las secciones más importantes a partir de las cuales construirá su sitio web.",
  help_center_group_4_video_4_description:
    "Aprenda a reemplazar el encabezado y el pie de página en el creador de sitios web",
  help_center_group_4_video_5_description:
    "Secciones en el creador de sitios web. Aprenda a agregar secciones y cómo administrarlas.",
  help_center_group_4_video_6_description:
    "En este video describimos el proceso de agregar su propio dominio. Un buen dominio es la clave del éxito de tu negocio. Vea cómo configurar su dirección en la red.",
  help_center_group_5_video_1_description:
    "Una aplicación móvil con sistema de reservas es algo que todos te envidiarán. Vea cómo crear su propia aplicación y colocarla en App Store y Google Play.",
  help_center_group_5_video_2_description:
    "Vea cómo se ve su aplicación móvil en la tienda. En este vídeo aprenderás cómo hacerlo.",
  help_center_group_6_video_1_description:
    "Un sistema de reservas con pagos es algo que las empresas modernas necesitan. Vea cómo vincular su cuenta de Stripe a su cuenta de Calendesk.",
  help_center_group_6_video_2_description:
    "Stripe Payments ofrece varias opciones de configuración adicionales. Vea cómo habilitar pagos adicionales por parte del operador Przelewy24.",
  help_center_group_6_video_3_description:
    "Vea cómo cobrar por sus servicios en Calendesk. Hablaremos aquí de prepagos para la visita, estados de pago y verás dónde buscar transacciones para pagos.",
  help_center_group_6_video_4_description:
    "¿Una simple tienda en línea en Calendesk? ¡Es posible! Vea cómo conectar sus productos por los que cobra pagos únicos con su sitio web.",
  related_articles: "Artículos relacionados",
  article_1_title: "Descripción general de la plataforma de Calendesk",
  article_2_title: "Perfil - su empresa",
  article_3_title: "Empleados: agregar un empleado",
  article_4_title: "Servicios - añadir una categoría",
  article_5_title: "Servicios - agregar un servicio",
  article_6_title: "Servicios - lista de servicios",
  article_7_title: "Empleados - calendario",
  article_8_title: "Clientes - una lista de clientes",
  article_9_title: "Clientes - añadir un cliente",
  article_10_title: "Clientes - reservas",
  article_11_title: "Clientes - boletín",
  article_12_title: "Clientes - notificaciones",
  article_13_title: "Ajustes - notificaciones",
  article_14_title: "Extras - sitio web",
  article_15_title: "Sitio web - personalizados",
  article_16_title: "Sitio web - dominio",
};
