var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.formElements),function(element,index){return _c('v-row',{key:index,attrs:{"justify":"center"}},[_c('v-col',{class:{
      'py-1' : element.type !== _vm.dynamicFormTypes.CHECKBOX,
      'py-0' : element.type === _vm.dynamicFormTypes.CHECKBOX,
    }},[(element.type !== _vm.dynamicFormTypes.CHECKBOX && element.description)?_c('div',{staticClass:"mb-2 mt-4 cd-custom-field-description-wrapper",domProps:{"innerHTML":_vm._s(element.description)}}):_vm._e(),(element.type === _vm.dynamicFormTypes.TEXT_FIELD)?_c('v-text-field',{attrs:{"validate-on-blur":"","outlined":"","label":element.label,"hide-details":"auto","color":"wb_color_primary__color__","dark":_vm.draftConfiguration.wb_dark_mode__checkbox__}}):_vm._e(),(element.type === _vm.dynamicFormTypes.TEXT_AREA)?_c('v-textarea',{attrs:{"validate-on-blur":"","outlined":"","label":element.label,"hide-details":"auto","color":"wb_color_primary__color__","dark":_vm.draftConfiguration.wb_dark_mode__checkbox__}}):_vm._e(),(element.type === _vm.dynamicFormTypes.EMAIL)?_c('v-text-field',{attrs:{"validate-on-blur":"","outlined":"","label":_vm.$trans('email'),"hide-details":"auto","color":"wb_color_primary__color__","dark":_vm.draftConfiguration.wb_dark_mode__checkbox__}}):_vm._e(),(element.type === _vm.dynamicFormTypes.PHONE)?_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dark":_vm.draftConfiguration.wb_dark_mode__checkbox__,"outlined":"","label":_vm.$trans('country'),"hide-details":"auto","color":"wb_color_primary__color__"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"color":"wb_color_primary__color__","outlined":"","label":_vm.$trans('phone'),"hide-details":"auto","dark":_vm.draftConfiguration.wb_dark_mode__checkbox__}})],1)],1)],1):_vm._e(),(element.type === _vm.dynamicFormTypes.CHECKBOX)?[_c('div',{staticClass:"d-flex align-top mt-4"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":"auto","id":("checkbox_" + index)},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"pointer cd-checkbox-label",class:{
                  'theme--light': !_vm.isDarkMode,
                  'theme--dark': _vm.isDarkMode,
                },domProps:{"innerHTML":_vm._s(element.description)}})]},proxy:true}],null,true)})],1)]:_vm._e(),(element.type === _vm.dynamicFormTypes.RADIO)?_c('v-radio-group',{attrs:{"dark":_vm.draftConfiguration.wb_dark_mode__checkbox__,"hide-details":"auto"}},_vm._l((element.radioOptions),function(option){return _c('v-radio',{key:option,attrs:{"dark":_vm.draftConfiguration.wb_dark_mode__checkbox__,"label":option,"value":option}})}),1):_vm._e(),(element.type === _vm.dynamicFormTypes.ADDRESS)?[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"px-0 pt-0"},[_c('v-text-field',{ref:index + 'street_and_number',refInFor:true,attrs:{"outlined":"","label":_vm.$trans('street_and_number'),"hide-details":"auto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-0 pr-2",attrs:{"cols":_vm.isMobile ? 6 : 4}},[_c('v-text-field',{ref:index + '_postcode',refInFor:true,attrs:{"outlined":"","label":_vm.$trans('postcode'),"hide-details":"auto","autocomplete":"postal-code"}})],1),_c('v-col',{staticClass:"px-0",class:{
              'pr-2' : !_vm.isMobile
            },attrs:{"cols":_vm.isMobile ? 6 : 4}},[_c('v-text-field',{ref:index + '_city',refInFor:true,attrs:{"outlined":"","label":_vm.$trans('city'),"hide-details":"auto","autocomplete":"address-level2"}})],1),_c('v-col',{staticClass:"px-0 d-flex align-center",class:{
              'py-0 pt-0': !_vm.isMobile
            },attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('v-autocomplete',{attrs:{"outlined":"","hide-details":"auto","validate-on-blur":"","items":[],"label":_vm.$trans('country')}})],1)],1)],1)]:_vm._e()],2)],1)}),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex align-center justify-center"},[_c('default-button',{attrs:{"button":_vm.button}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }