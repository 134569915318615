export default {
  services_number_title: "Number of services to choose from",
  additional_information_after_booking_title:
    "Additional information in the email after booking",
  additional_information_after_booking_subtitle:
    "Additional information that will be sent in emails related to service reservations (creation, modification, and reminders about the reservation). If you have edited the notifications, make sure the appropriate variable is set in their content: [ADDITIONAL_INFORMATION_AFTER_BOOKING].",
  additional_information_after_booking_dummy_text:
    "This is a sample text that will be replaced with additional information after booking, if such information has been provided in the service settings.",
  plugins_storage_100gb_cd_base_pro_name: "File storage 100 GB",
  plugins_storage_500gb_cd_base_pro_name: "File storage 500 GB",
  plugins_storage_100gb_cd_base_pro_description:
    "More file space. Gain 100 GB of additional space for file sharing with clients and receiving attachments via booking forms. An ideal solution to streamline communication and improve customer service.",
  plugins_storage_500gb_cd_base_pro_description:
    "Maximum space for your files. Use 500 GB of storage to exchange files with clients and receive attachments via booking forms without limitations. Essential for rapidly growing businesses that value professionalism and convenience.",
  bookings_this_month: "Bookings this month",
  bookings_all_processed: "All bookings",
  simple_store_product_transactions_this_month: "Products sold this month",
  files_storage_usage: "Used file storage",
  files_storage_usage_from_available_prefix: "of available",
  not_available_in_standard_plan: "Feature not available in the Standard plan",
};
